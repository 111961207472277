import { useState, useEffect, createContext, useContext } from 'react'
import { useMobileAPI } from 'global'

const Context = createContext()
const listenerID = 'LogProvider'

export function LogProvider(props) {
  const mobile = useMobileAPI()
  const [logs, setLogs] = useState([])

  useEffect(() => {
    mobile.listen(listenerID, (event, payload) => {
      if (event != 'log' && event != 'error') return
      setLogs(prev => [...prev, payload])
    })
    return () => mobile.unlisten(listenerID)
  }, [])

  // TODO: add log methods for client-side logging as well
  const value = {
    logs,
    clear: () => setLogs([]),
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useLog() {
  return useContext(Context)
}
