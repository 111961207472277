import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useStore } from 'global'
import { useBLE } from 'utils'
import Screen, { State } from './screen'

export default function BLEProvision() {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const {
    deviceSerialNumber: serialNumber,
    provision,
    disconnect,
  } = useBLE()
  const {
    store,
    updateSecurityDevice,
    securityDevicesBySerial,
    positionsBySecurityDevice,
  } = useStore()

  const device = securityDevicesBySerial?.[serialNumber] || null
  const position = positionsBySecurityDevice?.[device?.id] || null
  const [state, setState] = useState(State.blank)

  // Set the store hub in provision mode
  useEffect(() => {
    if (!store.connectHubIds?.length) return toast.error(t('error.provisionMode'))

    const hubID = Math.max(...store.connectHubIds)
    api.startProvisionMode(store.id, hubID).catch(() =>
      toast.error(t('error.provisionMode')))
  }, [])

  const checkCounter = useRef(0)
  const fail = () => {
    checkCounter.current = 0
    setState(State.failure)
  }
  const checkProvisionStatus = () => {
    if (checkCounter.current >= 3) return fail()
    checkCounter.current += 1
    setTimeout(() => {
      api.getSecurityDeviceBySerial(serialNumber).then(response => {
        const lock = response?.securityDevices?.[0]
        if (!lock) return checkProvisionStatus() // try again every 3 seconds, up to 3 times

        updateSecurityDevice(lock)
        checkCounter.current = 0
        setState(State.success)

        // After showing the success message for 5 seconds, disconnect, so that the next time we connect, the device data shows in the UI
        setTimeout(() => setState(State.blank), 5000)
      }).catch(() => fail())
    }, 3000)
  }

  const sendProvision = () => {
    setState(State.loading)
    provision()
    checkProvisionStatus()
  }

  return (
    <Screen
      position={position}
      device={device}
      onProvision={sendProvision}
      onDisconnect={disconnect}
      state={state}
    />
  )
}
