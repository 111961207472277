import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI } from 'global'
import { TextField, Button } from 'components'
import './styles.scss'

export default function RegionEdit(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { region, onFinish } = props
  const nameRef = useRef()
  const onSave = () => {
    const name = nameRef.current.value.trim()
    if (!name) return toast.error(t('error.nameRequired'))
    api.saveRegion({ id: region?.id, name }).then(response => {
      toast.success(t('global.success'))
      onFinish?.(response.regions[0])
    }).catch(e => toast.error(e))
  }
  return (
    <div className='region-edit-comp col'>
      <h1>{t(`regionEdit.${region?.id ? 'edit' : 'create'}Title`)}</h1>
      <TextField ref={nameRef} defaultValue={region?.name} autoFocus />
      <Button onClick={onSave}>{t('form.save')}</Button>
    </div>
  )
}
