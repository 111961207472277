import { useTranslation } from 'react-i18next'
import { useAPI, useStore } from 'global'
import { toast } from 'react-toastify'
import Screen from './screen'

export default function StoreEdit(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { store, updateStore } = useStore()
  const { storeData, onFinish } = props

  const onSubmit = data => {
    api.saveStore(data).then(response => {
      toast.success(t(store?.id ? 'success.storeEdit' : 'success.storeCreate'))
      const newStore = response?.stores?.[0]
      const newAddress = response?.addresses?.[0]
      const newRegion = response?.regions?.[0]
      if (store?.id && store.id == newStore.id) updateStore(newStore, newAddress, newRegion)
      onFinish?.(true)
    }).catch(()=>toast.error(t('error.storeSave')))
  }

  return (
    <Screen storeData={storeData} onSubmit={onSubmit} onCancel={()=>onFinish?.(false)} />
  )
}
