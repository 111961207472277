import React, { useState, useContext } from 'react'
import { useAPI } from 'global'
const Context = React.createContext()

export function StoreProvider(props) {
  const [storeData, setStoreData] = useState()
  const api = useAPI()

  const getStore = storeID => {
    if (!storeID) return
    api.getStore(storeID).then(response => {
      const store = response?.stores?.[0]
      const address = response?.addresses?.[0]
      const region = response?.regions?.[0]

      const securityDevices = {}
      const securityDevicesBySerial = {}
      const securityDevicesByParent = {}
      response?.securityDevices?.forEach(sd => {
        if (sd.unhealthyDetail)
          sd.unhealthyPorts = [...sd.unhealthyDetail.matchAll(/\d+/g)].map(s => Number(s))
        securityDevices[sd.id] = sd
        securityDevicesBySerial[sd.serialNumber] = sd
        if (sd.parentId) securityDevicesByParent[sd.parentId] = sd
      })

      const geometries = {}
      response?.geometries?.forEach(g => geometries[g.id] = g)

      const floors = {}
      response?.floors?.forEach(f => floors[f.id] = { ...f, zoneType: 'floor' })

      const areas = {}
      response?.areas?.forEach(a => areas[a.id] = { ...a, zoneType: 'area' })

      const fixtures = {}
      response?.fixtures?.forEach(f => fixtures[f.id] = { ...f, zoneType: 'fixture' })

      const positions = {}
      const positionsBySecurityDevice = {}
      response?.positions?.forEach(p => {
        positions[p.id] = { ...p, zoneType: 'position' }
        if (p.securityDeviceId) positionsBySecurityDevice[p.securityDeviceId] = p
      })

      const areasByFloor = listOfBy(areas, 'floorId')
      const fixturesByArea = listOfBy(fixtures, 'areaId')
      const positionsByFixture = listOfBy(positions, 'fixtureId')

      const connectHubs = response?.connectHubs

      setStoreData({
        store,
        address,
        region,
        floors,
        areas,
        areasByFloor,
        fixtures,
        fixturesByArea,
        positions,
        positionsByFixture,
        positionsBySecurityDevice,
        securityDevices,
        securityDevicesBySerial,
        securityDevicesByParent,
        geometries,
        connectHubs,
      })
    }).catch(()=>{})
  }

  const selectStore = storeID => {
    if (storeID != storeData?.store?.id) {
      setStoreData(null)
      getStore(storeID)
    }
  }

  const updateStore = (newStore, newAddress, newRegion) => {
    setStoreData({
      ...storeData,
      store: newStore,
      address: newAddress,
      region: newRegion,
    })
  }

  // This function is to try to adjust the store hierarchy based on saving or editing certain
  // resources, in order to try to reduce the number of times we need to re-fetch the entire store
  // hierarchy, which can be a very heavy API call.
  const saveZoneComponent = (type, resource) => {
    // create new instances of objects that have changed, in order to trigger any listeners/updates
    // that are dependent on them.
    const collection = `${type}s`
    const newStoreData = { ...storeData }
    newStoreData[collection] = { ...newStoreData[collection] }
    const newResourceData = { ...resource, zoneType: type }
    newStoreData[collection][resource.id] = newResourceData

    if (type == 'area')
      newStoreData.areasByFloor = listOfBy(newStoreData.areas, 'floorId')

    if (type == 'fixture')
      newStoreData.fixturesByArea = listOfBy(newStoreData.fixtures, 'areaId')

    if (type == 'position') {
      newStoreData.positionsBySecurityDevice = {}
      Object.values(newStoreData.positions).forEach(p => {
        if (p.securityDeviceId) newStoreData.positionsBySecurityDevice[p.securityDeviceId] = p
      })
      newStoreData.positionsByFixture = listOfBy(newStoreData.positions, 'fixtureId')
    }

    setStoreData(newStoreData)
  }

  const updateSecurityDevice = securityDevice => {
    setStoreData(prevStoreData => {
      if (!prevStoreData) return prevStoreData
      const newStoreData = {
        ...prevStoreData,
        securityDevices: {
          ...prevStoreData.securityDevices,
          [securityDevice.id]: securityDevice,
        },
        securityDevicesBySerial: {
          ...prevStoreData.securityDevicesBySerial,
          [securityDevice.serialNumber]: securityDevice,
        },
        securityDevicesByParent: {...prevStoreData.securityDevicesByParent},
      }

      // Remove device from securityDevicesByParent if the new security device lacks a parent ID
      const oldDevice = prevStoreData.securityDevices[securityDevice.id]
      if (oldDevice?.parentId && !securityDevice.parentId)
        delete newStoreData.securityDevicesByParent[oldDevice.parentId]

      // Update the parent ID if the new security device has one
      if (securityDevice.parentId)
        newStoreData.securityDevicesByParent[securityDevice.parentId] = securityDevice

      return newStoreData
    })
  }

  const value = {
    ...storeData,
    selectStore,
    updateStore,
    refresh: () => getStore(storeData?.store?.id),
    saveZoneComponent,
    updateSecurityDevice,
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useStore() {
  return useContext(Context)
}

function listOfBy(collection, idName) {
  const d = {}
  Object.values(collection).forEach(item => {
    const parentID = item[idName]
    d[parentID] = d[parentID] || []
    d[parentID].push(item)
  })
  return d
}
