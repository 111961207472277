import { useState, useEffect, useRef, createContext, useContext } from 'react'
import { useAPI, useStore } from 'global'
const Context = createContext()

export function VirtualKeyProvider(props) {
  const cachedKeys = useRef({})
  const api = useAPI()
  const { store } = useStore()
  const [virtualKey, setVirtualKey] = useState(null)

  // When a user changes stores, clear the virtual key.
  useEffect(() => setVirtualKey(null), [store?.id])

  const cacheAndSet = response => {
    const k = response?.virtualKeys[0]
    cachedKeys.current[store?.id] = k
    setVirtualKey(k)
  }

  const createVirtualKey = () => api.createVirtualKey(store?.id).then(cacheAndSet).catch(() => {})

  const checkOrCreateKey = () => {
    if (!store) return
    let key = cachedKeys.current[store?.id]
    if (!key) return createVirtualKey()

    // example expirationDate: "2024-07-26T06:04:28.377Z"
    const exp = new Date(key.expirationDate)
    const expired = (exp <= new Date())
    if (expired) return createVirtualKey()

    api.getVirtualKey(key.id).then(cacheAndSet).catch(createVirtualKey)
  }

  const value = {
    virtualKey,
    createVirtualKey: checkOrCreateKey,
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useVirtualKey() {
  return useContext(Context)
}
