import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ListView, Select, Button, PageHeader, openModal, closeModal } from 'components'
import { KeyType } from 'enums'
import KeyCell from './keyCell'
import Filters from './filter'
import './styles.scss'

export default function KeysScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    keys,
    onQuery,
    onCreate,
    onEdit,
    onChangeKeyType,
    selectedKeyType,
    pagination,
  } = props

  const filtersRef = useRef({
    page: 1,
    searchTerm: null,
  })

  const onPageChanged = f => {
    filtersRef.current.page = f.page
    onQuery(filtersRef.current)
  }

  const onFiltersChanged = f => {
    const newFilters = { ...filtersRef.current, ...f }
    Object.keys(newFilters).forEach(k => {
      if (newFilters[k] === null) delete newFilters[k]
    })
    filtersRef.current = newFilters
    onQuery(filtersRef.current)
    closeModal()
  }

  const showFilters = () => openModal(
    <Filters filters={filtersRef.current} onFilter={onFiltersChanged} />
  )

  return (
    <>
      <PageHeader title={t('resource.key', { count: 100 })}>
        <Select ref={selectedKeyType} onChange={onChangeKeyType}>
          <option key={KeyType.rfid} value={KeyType.rfid}>VERSA</option>
          <option key={KeyType.ble} value={KeyType.ble}>BLE</option>
        </Select>
        <Button flat icon="add" onClick={onCreate} />
        <Button flat icon="filter_alt" onClick={showFilters} />
      </PageHeader>
      <ListView
        className='list'
        data={keys}
        cell={KeyCell}
        onQuery={onPageChanged}
        onSelect={onEdit}
        meta={pagination}
      />
    </>
  )

}
