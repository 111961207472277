import { createContext, useContext } from 'react'
const Context = createContext()

// MobileAPI is the API the web app uses to communicate with the mobile app
class MobileAPI {
  static get isMobile() { return navigator.userAgent.includes('MAEMobile') }
  static get isAndroid() { return navigator.userAgent.includes('Android') }
  static get isIOS() { return navigator.userAgent.includes('iOS') }
  static get _listeners() {
    this.__listeners = this.__listeners || {}
    return this.__listeners
  }

  static get version() {
    if (this.isAndroid) return `a${navigator.userAgent.split('/')[1] || 'NA'}`
    if (this.isIOS) return `i${navigator.userAgent.split('/')[1] || 'NA'}`
    return null
  }

  // post is used to send a message or call a function in the mobile app
  static post(methodName, payload) {
    if (!this.isMobile) return

    if (this.isAndroid) {
      // The native android hooks are weird and will throw an error if you pass a null or undefined
      // argument for functions that don't take any arguments. So we have to pass the exact number of arguments.
      const method = window.AndroidAPI[methodName]?.bind(window.AndroidAPI)
      return payload ? method?.(payload) : method?.()
    } else if (this.isIOS) return window.webkit.messageHandlers[methodName]?.postMessage(payload || null)
  }

  // dispatch is called by the mobile app when an event occurs
  static dispatch(event, payload) {
    console.log('Mobile Event: ', event, payload)
    Object.values(this._listeners).forEach(cb => cb(event, payload))
  }

  static listen(listenerID, listener) { this._listeners[listenerID] = listener }
  static unlisten(listenerID) { delete this._listeners[listenerID] }
}
window.MobileAPI = MobileAPI

export function MobileAPIProvider(props) {
  return (
    <Context.Provider value={MobileAPI}>
      {props.children}
    </Context.Provider>
  )
}

export default function useMobileAPI() {
  return useContext(Context)
}
