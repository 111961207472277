import { useTranslation } from 'react-i18next'
import { PageHeader, ListView } from 'components'
import IOTDashboardCell from './iotDashboardCell'
import './styles.scss'

export default function IOTDashboardScreen(props) {
  const { data, onQuery, onSelect } = props
  const { t } = useTranslation(null, { useSuspense: false })

  return (
    <div className='iot-dashboard-comp page'>
      <PageHeader title={t('iotDashboard.iotDashboard')}/>
      <ListView
        data={data}
        cell={IOTDashboardCell}
        onQuery={onQuery}
        onSelect={onSelect}
      />
    </div>
  )
}