import { useEffect, useState } from 'react'
import './styles.scss'
const modalSingleton = { }

export function openModal(children) {
  modalSingleton.setChildren?.(children)
  modalSingleton.setIsOpen?.(true)
}

export function closeModal() {
  modalSingleton.setIsOpen?.(false)
  modalSingleton.setChildren?.(null)
}

export default function Modal() {
  const [isOpen, setIsOpen] = useState(false)
  const [children, setChildren] = useState()
  useEffect(() => {
    modalSingleton.setIsOpen = setIsOpen
    modalSingleton.setChildren = setChildren
    return () => {
      modalSingleton.setIsOpen = null
      modalSingleton.setChildren = null
    }
  }, [])
  const close = () => {
    setIsOpen(false)
    setChildren(null)
  }
  const cancelBubble = event => event.stopPropagation()
  return isOpen ? (
    <div id="modal-overlay-comp" onClick={close}>
      <div id="modal-container" onClick={cancelBubble}>
        {children}
      </div>
    </div>
  ) : <></>
}
