import { useTranslation } from 'react-i18next'
import { Button, LoadingSpinner, PageHeader } from 'components'
import PositionCell from 'components/positionCell'
import './styles.scss'

export default function Screen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    positions,
    isFiltered,
    onFilter,
  } = props

  return (
    <div className='positions-comp page'>
      <PageHeader title={t('resource.position', { count: 100 })}>
        <Button isActive={isFiltered} flat icon="filter_alt" onClick={onFilter} />
      </PageHeader>
      {positions ? (
        <div className='list'>
          {positions.map((p, idx) => <PositionCell key={idx} position={p} />)}
        </div>
      ) : <LoadingSpinner />}
    </div>
  )
}
