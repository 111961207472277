import { useRef, useState } from 'react'
import { useAPI } from 'global'
import { Button, TextField, Modal } from 'components'
import { toast } from 'react-toastify'
import './styles.scss'
import { useTranslation } from 'react-i18next'

export default function PasswordForgotRequest(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const emailRef = useRef()
  const [formSubmitted, setFormSubmitted] = useState(false)

  const submitEmail = e => {
    e.preventDefault()

    const email = emailRef?.current.value.trim()
    if (!validate(t, email)) return

    const userData = {
      username: email,
    }

    api.requestResetPassword(userData).then(() => {
      toast.success(t('success.request'))
      setFormSubmitted(true)
    }).catch(()=>{})
  }

  return (
    <Modal onClose={props.onClose}>
      <div className='forgot-password-comp col'>
        {!formSubmitted ? (
          <div className='col'>
            <h1>{t('forgotPassword.resetPassword')}</h1>
            <form onSubmit={e => submitEmail(e)} className='col'>
              <TextField required type='email' ref={emailRef} label={t('user.email')} />
              <Button type='submit'>{t('form.submit')}</Button>
            </form>
          </div>
        ) : (
          <div className='col'>
            <h1>{t('forgotPassword.checkYourEmail')}</h1>
            <p>{t('forgotPassword.sentALink')}</p>
          </div>
        )}
      </div>
    </Modal>
  )
}

const validate = (t, email) => {
  const errors = []

  if (!email) errors.push(t('error.noEmail'))

  const valid = !(errors.length > 0)
  if (!valid) toast.error(errors.join(' '))
  return valid
}
