import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef, useMemo } from 'react'
import { ListView, Button, Select, DateField, openModal, closeModal } from 'components'
import Filter from './filter'
import ReportCell from './reportCell'
import './styles.scss'

export default function Screen(props) {
  const { reports, meta, onQuery, onExport } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const reportTypeField = useRef()
  const startDateField = useRef()
  const endDateField = useRef()
  const paginationRef = useRef({})
  const [isLoading, setIsLoading] = useState(false)

  const query = () => {
    setIsLoading(true)
    onQuery(getFilters())
  }
  const onGetPage = pagination => {
    paginationRef.current = pagination
    query()
  }
  const getFilters = () => ({
    reportType: reportTypeField.current.value,
    startDate: startDateField.current.value,
    endDate: endDateField.current.value,
    ...paginationRef.current,
  })

  useEffect(() => setIsLoading(false), [reports])

  // onExport immediately exports the current page's data by default if no filters are passed in
  const exportPage = () => onExport()
  // onExport exports all pages based on passed in filters (ignoring any pagination)
  const exportAll = () => onExport(getFilters())

  const [defaultStartDate, defaultEndDate] = useMemo(() => {
    const today = new Date()
    const weekAgo = new Date()
    weekAgo.setDate(today.getDate() - 7)
    return [
      weekAgo.toISOString().split('T')[0],
      today.toISOString().split('T')[0],
    ]
  }, [])

  const onModalFilter = (reportType, startDate, endDate) => {
    closeModal()
    reportTypeField.current.value = reportType
    startDateField.current.value = startDate
    endDateField.current.value = endDate
    query()
  }

  const openFilter = () => openModal(
    <Filter
      startDate={startDateField.current.value}
      endDate={endDateField.current.value}
      reportType={reportTypeField.current.value}
      onFilter={onModalFilter}
    />
  )

  const listView = reports ? (
    <ListView
      className='list'
      data={reports}
      onQuery={onGetPage}
      cell={ReportCell}
      meta={meta}
      autoload={false}
    />
  ) : <p className="page-load-message">{t('reports.useFilters')}</p>

  return (
    <div className='reports-comp page'>
      <div className='row filters-container'>
        <div className='row filters'>
          <Select ref={reportTypeField}>
            <option value='operations'>{t('reports.operations')}</option>
            <option value='health'>{t('reports.health')}</option>
            <option value='marketing'>{t('reports.marketing')}</option>
          </Select>
          <DateField type='date' ref={startDateField} label={t('reports.filter.startDate')} defaultValue={defaultStartDate} />
          <DateField type='date' ref={endDateField} label={t('reports.filter.endDate')} defaultValue={defaultEndDate} />
          <Button onClick={query} isLoading={isLoading}>{t('form.submit')}</Button>
          <Button onClick={exportAll}>{t('reports.exportAll')}</Button>
          <Button onClick={exportPage}>{t('reports.exportPage')}</Button>
        </div>
        <Button isLoading={isLoading} id='btn-open-filter' flat onClick={openFilter} icon="filter_alt" />
      </div>
      {listView}
    </div>
  )
}
