import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button } from 'components'
import './styles.scss'

export default function StoresFilter(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { filters, onFilter } = props
  const { name, branchCode, city, state } = filters || {}

  const nameRef = useRef()
  const branchCodeRef = useRef()
  const cityRef = useRef()
  const stateRef = useRef()

  const submit = () => {
    const f = {
      name: nameRef.current.value.trim() || null,
      branchCode: branchCodeRef.current.value.trim() || null,
      city: cityRef.current.value.trim() || null,
      state: stateRef.current.value.trim() || null,
    }
    onFilter?.(f)
  }

  const clear = () => {
    const f = {
      name: nameRef.current.value = null,
      branchCode: branchCodeRef.current.value = null,
      city: cityRef.current.value = null,
      state: stateRef.current.value = null,
    }
    onFilter?.(f)
  }

  return (
    <div className="stores-filter-comp col">
      <TextField
        ref={nameRef}
        defaultValue={name}
        label={t('storeDetails.name')}
      />
      <TextField
        ref={branchCodeRef}
        defaultValue={branchCode}
        label={t('storeDetails.branchCode')} />
      <TextField
        ref={cityRef}
        defaultValue={city}
        label={t('address.city')} />
      <TextField
        ref={stateRef}
        defaultValue={state}
        label={t('address.state')} />
      <div className='button-row row'>
        <Button outline onClick={clear}>{t('form.clear')}</Button>
        <Button onClick={submit}>{t('form.submit')}</Button>
      </div>
    </div>
  )
}
