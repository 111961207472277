import { useRef, useImperativeHandle, forwardRef, useId } from 'react'
import './styles.scss'

function Select(props, ref) {
  const field = useRef()

  useImperativeHandle(ref, ()=>({
    get value() { return field.current.value },
    set value(v) { field.current.value = v },
  }))

  const { label, ...inputProps } = props
  const inputID = useId()

  return (
    <label htmlFor={inputID} className="select-comp">
      <span>{label}</span>
      <select
        id={inputID}
        name={label}
        { ...inputProps }
        ref={field}
      >
        {props.children}
      </select>
    </label>
  )
}

export default forwardRef(Select)
