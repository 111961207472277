import { useEffect } from 'react'
import mqtt from 'mqtt'
import { useStore, useSession } from 'global'
import parse from './parser'

const MQTTHost = getMQTTHost()

// The MQTTContextListener listens for changes in the session token or selected store and notifies the
// mqtt client so it can disconnect or reconnect as needed using the proper credentials or store context.
// This allows other components that merely want to listen to messages to not have to deal with any
// of the actual connection logic.
export function MQTTContextListener() {
  // Listen for changes in the selected store or authentication and set them on the client (which
  // may cause the client to disconnect if it's already connected)
  const { token, tokenID } = useSession()
  const { store } = useStore()
  useEffect(() => MQTTClient._setAuthentication(tokenID, token, store?.id), [store?.id, tokenID, token])

  // make sure to disconnect if this component is unmounted
  useEffect(() => {
    return () => MQTTClient._setAuthentication(null, null, null)
  }, [])

  // This component is purely functional, and doesn't render anything.
  return <></>
}

// The mqtt client will try to connect when the first listener registers itself, and will maintain a
// connection until the store context or token changes.
export default class MQTTClient {
  static listeners = {}

  static listen(id, callback) {
    this.listeners[id] = callback
    this._connect()
  }

  static unlisten(id) {
    delete this.listeners[id]
    // TODO: perhaps to save on traffic, we could auto-close the connection if another listener
    // doesn't register within a certain period of time.
    // We don't want to simply disconnect every time though, since connecting is a heavy transaction
    // on the API side, so we want to do it as little as possible.
  }

  static _setAuthentication(tokenID, token, storeID) {
    if (tokenID == this._tokenID && token == this._token && storeID == this._storeID) return
    this._disconnect()
    this._storeID = storeID
    this._tokenID = tokenID
    this._token = token
    this._connect()
  }

  static _connect() {
    if (this._client || !MQTTHost || !this._tokenID || !this._token || !this._storeID) return
    console.debug('mqtt connecting...')
    this._client = mqtt.connect(MQTTHost, {
      username: this._tokenID,
      password: this._token,

      // keepalive is a mechanism used to determine half-connections (if either the broker or the
      // client has lost connection, while the other thinks they're still connected)
      // It works by occasionally pinging the broker and expecting an ack back, and if it doesn't
      // get it, it will disconnect and try reconnecting.
      // This also help prevent http1.1 infrastructure or firewalls from killing the websocket due to inactivity.
      // (The default is 60s, but that doesn't seem to be short enough to prevent the connection from being killed)
      keepalive: 20,

      // This is the interval between reconnect attemps (in ms).
      // When set to 0, it does not automatically try to reconnect.
      // The default is 1000ms
      reconnectPeriod: 1000,
    })

    this._client.on('offline', () => console.debug(`mqtt:offline`))
    this._client.on('close', () => console.debug(`mqtt:close`))
    this._client.on('error', err => console.warn('mqtt:error', err))

    this._client.on('message', (topicWithIDs, message) => {
      console.debug(`mqtt:message - ${topicWithIDs}`)
      const topic = simplifyTopic(topicWithIDs)
      try {
        const state = parse(topic, message)
        if (!state) return
        Object.values(this.listeners).forEach(l => l(topic, state.toObject()))
      } catch (e) {
        console.warn('error while trying to parse realtime message')
        console.warn(e)
      }
    })

    this._client.on('connect', () => {
      console.debug('mqtt:connect')
      this._client?.subscribe(`store:${this._storeID}/#`, err => {
        console.log('subscribed')
        if (err) console.warn(`error subscribing to topic.`, err)
      })
    })
  }

  static _disconnect() {
    this._client?.end()
    this._client = null
  }
}

function simplifyTopic(topic) {
  // Merely grab the identifying parts of the topic message, so we can use that to determine which
  // deserializer to use.
  const segments = topic.split('/')
  segments.shift()
  return segments.map(s => s.split(':')[0]).join('/')
}

function getMQTTHost() {
  let host = process.env.REACT_APP_MQTT_HOST
  let url = host ? new URL(process.env.REACT_APP_MQTT_HOST) : null

  // If we're running locally, on a device or in an emulator, replace localhost with whatever our
  // app's hostname is, since that will be our local IP.
  if (url?.hostname == 'localhost') url.hostname = window.location.hostname

  return url?.toString()
}
