import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Select, Button } from 'components'
import { camelize } from 'utils'

export default function DeviceFilters(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { devices, filters, onFilter, onFinish } = props
  const { status, serial, deviceType, sortBy } = filters || {}
  const deviceTypes = [...new Set(devices.map(sd => sd.deviceType).sort())]

  const statusRef = useRef()
  const serialRef = useRef()
  const deviceTypeRef = useRef()
  const sortByRef = useRef()

  const submit = () => {
    const f = {
      status: statusRef.current?.value,
      serial: serialRef.current?.value.trim(),
      deviceType: deviceTypeRef.current?.value,
      sortBy: sortByRef.current?.value,
    }
    onFilter?.(f)
    onFinish()
  }

  const clear = () => {
    const f = {
      status: statusRef.current.value = '',
      serial: serialRef.current.value = '',
      deficeType: deviceTypeRef.current.value = '',
      sortBy: sortByRef.current.value = '',
    }
    onFilter(f)
  }

  return (
    <div className='device-filters-comp col'>
      <div>
        <p className='subtitle'>{t('device.filter.byAssignment')}</p>
        <Select ref={statusRef} defaultValue={status}>
          <option key='0' value=''>{t('device.filter.viewAll')}</option>
          <option key='1' value='assigned'>{t('device.filter.viewAssigned')}</option>
          <option key='2' value='unassigned'>{t('device.filter.viewUnassigned')}</option>
        </Select>
      </div>
      <div>
        <p className='subtitle'>{t('device.filter.byDeviceType')}</p>
        <Select ref={deviceTypeRef} defaultValue={deviceType}>
          <option key='0' value=''>{t('device.filter.viewAll')}</option>
          {deviceTypes.map(dt =>
            <option key={dt} value={dt}>{t(`device.type.${camelize(dt)}`)}</option>
          )}
        </Select>
      </div>
      <TextField
        label={t('device.filter.bySerialNumber')}
        ref={serialRef}
        defaultValue={serial}
      />
      <div>
        <p className='subtitle'>{t('device.sort.sortOptions')}</p>
        <Select ref={sortByRef} defaultValue={sortBy}>
          <option key='0' value=''>{t('device.sort.byState')}</option>
          <option key='1' value='serialNumber'>{t('device.sort.bySerialNumber')}</option>
          <option key='2' value='positionName' disabled={status === 'unassigned'}>{t('device.sort.byPositionName')}</option>
          <option key='3' value='updatedAt'>{t('device.sort.byUpdatedAt')}</option>
          <option key='4' value='createdAt'>{t('device.sort.byCreatedAt')}</option>
        </Select>
      </div>
      <div className='action-container row'>
        <Button outline onClick={clear}>{t('form.clear')}</Button>
        <Button onClick={submit}>{t('form.submit')}</Button>
      </div>
    </div>
  )
}