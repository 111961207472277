import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useSession, useMe, history } from 'global'
import { LoadingSpinner } from 'components'
import './styles.scss'

export default function AuthCodeExchange() {
  const api = useAPI()
  const session = useSession()
  const { me } = useMe()
  const { t } = useTranslation(null, { useSuspense: false })
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('code')
  const state = urlParams.get('state')
  const error = urlParams.get('error')
  const teamKey = localStorage?.getItem('teamKey')

  // This component immediately gets the URL params, posts to the API, and then redirects.
  // If the auth code is submitted twice, it will fail on the second attempt.
  // Since we are using StrictMode in development, we need some way to ensure the auth code gets submitted only once.
  // This ref is not necessary in production, but it exists to eliminate the error in development from this component remounting/resubmitting the auth code.
  const codePosted = useRef(false)

  // Submit code/state.
  useEffect(() => {
    if (codePosted.current || !code || !teamKey) return
    api.exchangeAuthCode(code, state, teamKey).then(response =>
      session.setAuthentication(response?.authentications?.[0])
    ).catch(() => {
      toast.error(t('error.invalidOAuthLogin'))
      history.replace('/')
    })
    codePosted.current = true
  }, [code, teamKey])

  // Redirect to orgs or stores once 'me' is available.
  useEffect(() => {
    if (!me) return
    me?.hasOrganizations
      ? history.replace('/organizations')
      : history.replace('/stores')
  }, [me])

  if (error) {
    history.push('/team/login')
    toast.error(t('error.invalidOAuthLogin'))
    return null
  }

  return (
    <div className='auth-code-exchange-comp page'>
      <LoadingSpinner />
    </div>
  )
}
