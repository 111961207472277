import { useTranslation } from 'react-i18next'
import { Button, openModal, closeModal } from 'components'
import './styles.scss'

export function confirm(onConfirm, text, subtext) {
  const confirmAndClose = () => {
    closeModal()
    onConfirm()
  }
  openModal(<Confirmation text={text} subtext={subtext} onConfirm={confirmAndClose} onCancel={closeModal} />)
}

export function Confirmation(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { text, subtext, onConfirm, onCancel } = props
  return (
    <div className='confirmation-comp col'>
      <h3>{text || t('global.areYouSure')}</h3>
      {subtext && <p className='subtitle'>{subtext}</p>}
      <div className='actions row'>
        <Button outline onClick={onCancel}>{t('form.cancel')}</Button>
        <Button danger onClick={onConfirm}>{t('form.agree')}</Button>
      </div>
    </div>
  )
}
