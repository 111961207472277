export default function Modal(props) {
  const cancelBubble = event => event.stopPropagation()
  return (
    <div id="modal-overlay-comp" onClick={props.onClose}>
      <div id="modal-container" onClick={cancelBubble}>
        {props.children}
      </div>
    </div>
  )
}
