import { useState, useRef } from 'react'
import { history, useAPI, useOrganization, useMe } from 'global'
import { StoreEdit, openModal, closeModal } from 'components'
import Screen from './screen'

// TODO: The query should probably be stored in the url params, so that navigating back from other
// pages returns you to the same list view, in addition to making it easier to link to certain
// pages.
export default function Stores() {
  const [stores, setStores] = useState([])
  const [pagination, setPagination] = useState()
  const api = useAPI()
  const { me } = useMe()
  const { organization } = useOrganization()

  const refreshing = useRef()
  const refresh = () => {
    if (refreshing.current) return
    refreshing.current = true
    const { page, name, branchCode, city, state } = filtersRef.current
    const query = {
      page,
      name,
      branch_code: branchCode,
      city,
      state,
      include: ['region', 'address'],
    }

    api.getStores(query).then(response => {
      refreshing.current = false
      const updatedStores = response?.stores?.map(store => {
        const address = response?.addresses?.find(a => a.addresseeId === store.id)
        const region = response?.regions?.find(r => r.id === store.regionId)
        return { ...store, address: address, region: region || null }
      })
      setStores(updatedStores)
      setPagination(response?.meta)
    })
  }

  // Store the filters in a reference so we can refer back to them on other page refresh triggers
  const filtersRef = useRef({})
  const onFilter = filters => {
    filtersRef.current = filters
    refresh()
  }

  const onSelect = store =>
    history.push(`/stores/${store.id}/positions`)

  const onCreate = () =>
    openModal(<StoreEdit onFinish={onStoreCreationFinished} />)

  const onStoreCreationFinished = created => {
    if (created) refresh()
    closeModal()
  }

  // If we're still fetching the org, then wait before fetching the list of stores
  // TODO: show a loading spinner here
  if (me?.hasOrganizations && !organization)
    return <></>

  return (
    <Screen
      stores={stores}
      onQuery={onFilter}
      onSelect={onSelect}
      onCreate={onCreate}
      pagination={pagination}
    />
  )
}
