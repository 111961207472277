import AppFooter from 'components/appFooter'
import './styles.scss'

export default function AppLayout(props) {
  return (
    <div className='app-layout-comp'>
      <div className='app-container'>
        {props.children}
      </div>
      <AppFooter />
    </div>
  )
}
