import { useTranslation } from 'react-i18next'
import { toFriendlyDate, toFriendlyTime } from 'utils'
import './styles.scss'

export default function IOTDashboardCell({ data, showDetails }) {
  return (
    <div className={`iot-dashboard-cell-comp ${showDetails ? 'show-details' : ''}`}>
      <p className='store-name'>{data?.name}</p>
      <div className='devices-container col'>
        {data?.meshController && <Device device={data?.meshController} />}
        {data?.pinpad && <Device device={data?.pinpad} />}
        {data?.doorController && <Device device={data?.doorController} />}
      </div>
    </div>
  )
}

const Device = ({ device }) => {
  const { t } = useTranslation(null, { useSuspense: false })
  return (
    <div className='row'>
      <div className='col'>
        <p>{t(`resource.${device?.type}`)}</p>
        <p className='subtitle detail'>{device?.identifier || device?.username || ''}</p>
        <p className='subtitle detail'>{t('iotDashboard.since', {date: toFriendlyDate(device?.since), time: toFriendlyTime(device?.since)})}</p>
      </div>
      {device?.status === 'online' ? <Online /> : <Offline />}
    </div>
  )
}

const Online = () => {
  const { t } = useTranslation(null, { useSuspense: false })
  return (
    <div className='row online'>
      <div className='on icon'></div>
      <p>{t('status.online')}</p>
    </div>
  )
}

const Offline = () => {
  const { t } = useTranslation(null, { useSuspense: false })
  return (
    <div className='row offline'>
      <div className='off icon'></div>
      <p>{t('status.offline')}</p>
    </div>
  )
}