import { useTranslation } from 'react-i18next'

export default function Duration({ milliseconds }) {
  const { t } = useTranslation(null, { useSuspense: false })

  return (
    <div id='duration-comp'>
      <p>{getDurationFromMs(milliseconds, t)}</p>
    </div>
  )
}

// Converts milliseconds to a formatted hours and minutes string.
const getDurationFromMs = (milliseconds, t) => {
  let seconds = Math.ceil(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  seconds = seconds % 60
  minutes = minutes % 60
  return t('duration.duration', { hours, minutes })
}