import { useState } from 'react'
import { useVirtualKey, useStore } from 'global'
import { useBLE } from 'utils'
import Screen, { State } from './screen'

export default function BLEUnlock() {
  const {
    deviceSerialNumber: serialNumber,
    unlock,
    disconnect,
  } = useBLE()
  const { virtualKey } = useVirtualKey()
  const { securityDevicesBySerial, positionsBySecurityDevice } = useStore()
  const device = securityDevicesBySerial?.[serialNumber]
  const position = positionsBySecurityDevice?.[device?.id]
  const [state, setState] = useState(State.locked)

  const sendUnlock = () => {
    if (!virtualKey) return
    setState(State.loading)
    unlock(virtualKey.serialNumber)

    setTimeout(() => {
      setState(State.unlocked)
      setTimeout(() => setState(State.locked), 3000)
    }, 3000)
  }

  return (
    <Screen
      position={position}
      onUnlock={sendUnlock}
      onDisconnect={disconnect}
      state={state}
    />
  )
}
