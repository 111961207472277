import { useTranslation } from 'react-i18next'
import { useStore } from 'global'
import { DeviceState as State } from 'enums'
import { openModal, DeviceDetails } from 'components'
import { useDeviceStateFor, camelize, iconFor } from 'utils'
import './styles.scss'

export default function DeviceCell({ securityDevice: sd }) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { securityDevices, securityDevicesByParent, positionsBySecurityDevice } = useStore()
  const securityDevice = securityDevices[sd.id] // Get the data from the context instead, so we can update our state in realtime
  const associatedDevice = securityDevices[securityDevice.parentId] || securityDevicesByParent[securityDevice.id]
  const pos = positionsBySecurityDevice[securityDevice.id]
  const assocPos = positionsBySecurityDevice[associatedDevice?.id]
  const position = pos || assocPos
  const state = useDeviceStateFor()(securityDevice)

  const openDetails = () => openModal(<DeviceDetails securityDevice={securityDevice} />)

  return (
    <div className='device-cell-comp cell col' onClick={openDetails}>
      <div className='row'>
        <p className={!position ? 'unassigned' : ''}>{position?.name || t('device.unassigned')}</p>
        <div className='row'>
          {iconFor(state.security)}
          {state.unhealthyReason && iconFor(State.unhealthy)}
          {state.alarming && iconFor(state.alarming)}
        </div>
      </div>
      <div className='col devices-container'>
        <div className='row device-container'>
          <p className='subtitle'>{t(`device.type.${camelize(securityDevice.deviceType)}`)}</p>
          <p className='serial'>{securityDevice.serialNumber}</p>
        </div>
        {associatedDevice &&
          <div className='row device-container'>
            <p className='subtitle'>{t(`device.type.${camelize(associatedDevice.deviceType)}`)}</p>
            <p className='serial'>{associatedDevice.serialNumber}</p>
          </div>
        }
      </div>
    </div>
  )
}
