import { useState } from 'react'
import { LoadingSpinner } from 'components'
import './styles.scss'

export default function Button(props) {
  const { onClick: callback, isLoading } = props
  const [isRunning, setIsRunning] = useState(false)

  const run = event => {
    let work = callback(event)
    if (work instanceof Promise) {
      setIsRunning(true)
      work.finally(() => setIsRunning(false))
    }
  }

  // if the click is being handled manually, don't propagate the click to the form (which will unintentionally cause a submit or page refresh)
  const onClick = callback ? event => {
    event.stopPropagation()
    if (!(isRunning || isLoading)) run(event)
  } : undefined

  let klass = 'button-comp'
  if (props.className) klass += ` ${props.className}`
  if (props.isActive) klass += ' active'
  klass += (props.isFlat || props.flat) ? ' flat' : ' floating'
  if (props.isRound || props.round) klass += ' round'
  if (props.isDanger || props.danger) klass += ' danger'
  if (props.highlight) klass += ' highlight'
  if (props.outline) klass += ' outline'

  return (
    <button
      id={props.id}
      className={klass}
      onClick={onClick}
      type={props.type || 'button'}>

      <div id="center-container">
        {(isRunning || isLoading) ? <LoadingSpinner small /> : (
          <>
            {props.icon && <i className="material-icons">{props.icon}</i>}
            <div id="content-container">{props.children}</div>
          </>
        )}
      </div>

      <div id="active-marker" />

    </button>
  )
}
