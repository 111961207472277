import { useTranslation } from 'react-i18next'
import { useBLE } from 'utils'
import { Button, LoadingSpinner } from 'components'
import addLock from 'assets/images/addLock.svg'
import lockAdded from 'assets/images/lockAdded.svg'
import './styles.scss'

export const State = {
  blank: 'blank',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
}

export default function BLEProvisionScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { deviceSerialNumber: serialNumber } = useBLE()
  const {
    position,
    device,
    onProvision,
    onDisconnect,
    state,
  } = props

  return (
    <div className='ble-provision-comp col'>
      <img src={state == State.success ? lockAdded : addLock} />
      <div className='content-container col'>

        {/* Scanning or Connected */}
        {serialNumber ?
          <div className='main-content col'>
            <h2>{position?.name || t('ble.unassignedLock')}</h2>
            <p className='subtitle'>{serialNumber}</p>
            {(state == State.blank) && <p className='info'>{device ? t('ble.provision.provisionedLockFound') : t('ble.provision.newLockFound')}</p>}
          </div>
          : <p className='scanning'>{t('ble.scanning')}</p>
        }

        {state == State.loading && <LoadingSpinner />}
        {state == State.success && <p>{t('ble.provision.success')}</p>}
        {state == State.failure && <p>{t('ble.provision.failure')}</p>}
      </div>

      {/* Action Buttons */}
      {serialNumber && (state == State.blank || state == State.failure) &&
        <div className='button-row row'>
          <Button onClick={onProvision}>{t('ble.provision.provision')}</Button>
          <Button outline onClick={onDisconnect}>{t('ble.disconnect')}</Button>
        </div>
      }
    </div>
  )
}
