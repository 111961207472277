import './styles.scss'

export default function PageHeader(props) {
  return (
    <div className='page-header-comp'>
      <div className='title'>{props.title}</div>
      <div className='actions'>{props.children}</div>
    </div>
  )
}
