import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI } from 'global'
import { Button, TextField, LoadingSpinner } from 'components'
import './styles.scss'

export default function PasswordEdit(props) {
  const api = useAPI()
  const { t } = useTranslation(null, { useSuspense: false })
  const { user, onFinish } = props
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const passcodeRef = useRef()
  const passcodeConfirmRef = useRef()

  const submit = e => {
    e.preventDefault()

    const password = passwordRef?.current?.value.trim()
    const passwordConfirm = passwordConfirmRef?.current?.value.trim()
    const passcode = passcodeRef?.current.value.trim()
    const passcodeConfirm = passcodeConfirmRef?.current.value.trim()

    if (!validate(t, password, passwordConfirm, passcode, passcodeConfirm)) return

    const userData = {
      id: user.id,
      password,
      passcode,
    }

    Object.keys(userData).forEach(k => {
      if (!userData[k]) delete userData[k]
    })

    api.savePassword(userData).then(() => {
      userData.password && toast.success(t('success.password'))
      userData.passcode && toast.success(t('success.passcode'))
      onFinish?.()
    }).catch(()=>toast.error(t('error.invalidPassword')))
  }

  // This shouldn't ever be the case
  if (!user) return <LoadingSpinner />

  return (
    <div className='edit-password-comp'>
      <form onSubmit={submit}>
        <div className="header">
          <Button flat icon="chevron_left" onClick={()=>onFinish?.()} />
          <h3>{user.externallyAuthenticated ? t('profile.editPasscode') : t('profile.editPassword')}</h3>
        </div>
        {!user.externallyAuthenticated &&
          <>
            <p className='subtitle'>{t('forgotPassword.instructions')}</p>
            <p className='subtitle'>{t('forgotPassword.passwordInstructions')}</p>
          </>
        }
        <p className='subtitle'>{t('forgotPassword.passcodeInstructions')}</p>
        {!user.externallyAuthenticated &&
          <>
            <TextField
              ref={passwordRef}
              type="password"
              minLength='8'
              label={t('profile.password')}
            />
            <TextField
              ref={passwordConfirmRef}
              type="password"
              minLength='8'
              label={t('profile.passwordConfirm')}
            />
          </>
        }
        <TextField
          ref={passcodeRef}
          type="password"
          pattern='[0-9]+'
          minLength='4'
          maxLength='4'
          label={t('profile.passcode')}
        />
        <TextField
          ref={passcodeConfirmRef}
          type="password"
          pattern='[0-9]+'
          minLength='4'
          maxLength='4'
          label={t('profile.passcodeConfirm')}
        />
        <Button type='submit'>{t('form.save')}</Button>
      </form>
    </div>
  )
}

const validate = (t, password, confirmPassword, passcode, confirmPasscode) => {
  const errors = []

  if (!password && !confirmPassword && !passcode && !confirmPasscode)
    errors.push(t('error.fieldsEmpty'))

  if (password) {
    if (password != confirmPassword)
      errors.push(t('error.passwordsMustMatch'))
  }

  if (passcode) {
    if (passcode != confirmPasscode)
      errors.push(t('error.passcodesMustMatch'))

    for (let index in passcode) {
      const idx = Number(index)
      if (passcode[idx] === passcode[idx+1]) {
        errors.push(t('error.noRepeatingNumbers'))
        break
      }
    }
  }

  const valid = !(errors.length > 0)
  if (!valid) toast.error(errors.join(' '))
  return valid
}
