import React, { useEffect } from 'react'
import Screen from './screen'

export default function ListView(props) {
  const { data, meta, onQuery, cell, onSelect, className, alternateStyle, autoload = true } = props
  const page = meta?.currentPage || meta?.current_page || 1
  const pageCount = Math.ceil(meta?.total / (meta?.perPage || meta?.per_page)) || 1

  // on mount, query the first page
  useEffect(() => {
    if (autoload) onQuery?.({ page })
  }, [])

  const loadPage = newPage => {
    if (newPage < 1 || newPage > pageCount) return
    onQuery?.({ page: newPage })
  }

  // if a cell updates its data, requery the current page
  const onUpdate = () => onQuery?.({ page })

  return (
    <Screen
      meta={meta}
      className={className}
      data={data}
      cell={cell}
      currentPage={page}
      pageCount={pageCount}
      onPrevPage={()=>loadPage(page-1)}
      onNextPage={()=>loadPage(page+1)}
      onSelect={onSelect}
      onUpdate={onUpdate}
      alternateStyle={alternateStyle}
    />
  )
}
