import { useEffect } from 'react'
import { Router } from '@MTIConnect/sw-react-common'
import { useStore } from 'global'
import RealtimeUpdater from 'components/realtimeUpdater'
import Tabbar from './tabbar'
import './styles.scss'

import Details from 'pages/storeDetails'
import Keys from 'pages/keys'
import Positions from 'pages/positions'
import Hierarchy from 'pages/storeHierarchy'
import Devices from 'pages/devices'
import Reports from 'pages/reports'
import BLELockManager from 'pages/bleLockManager'

const { Switch, Route } = Router

export default function StoreLayout(props) {
  // This will grab the store id from the url and make sure the store context loads it in, just in
  // case a user navigates directly to this page through a link or does a hard refresh while on
  // these pages (thus, losing the in-memory selected store)
  const urlStoreID = props.storeID
  const { store, selectStore } = useStore()

  useEffect(() => {
    if (store?.id != urlStoreID) selectStore(urlStoreID)
  }, [urlStoreID])

  return (
    <div id="store-layout-comp">
      <RealtimeUpdater />
      <Tabbar />
      <Switch>
        <Route path='details' element={Details} />
        <Route isDefault path='positions' element={Positions} />
        <Route path='zones' element={Hierarchy} />
        <Route path='devices' element={Devices} />
        <Route path='keys' element={Keys} />
        <Route path='reports' element={Reports} />
        <Route path='ble-lock-manager' element={BLELockManager} />
      </Switch>
    </div>
  )
}
