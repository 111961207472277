import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CableType } from 'protoModels/mti/store/cable/state_pb'
import { useStore, useAPI, useMe } from 'global'
import { DeviceType as DT } from 'enums'
import { camelize, useDeviceStateFor, iconFor, toHash, toFriendlyDate, toFriendlyTime } from 'utils'
import { Button, UnhealthyIssue as Issue } from 'components'
import DeviceAssignment from './deviceAssignment'
import PortCell from './portCell'
import './styles.scss'

export default function PositionModal(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const api = useAPI()
  // Get the position data from the context, so if it's ever updated, such as by the device
  // assignment, we get notified as well.
  const { position: { id: positionID } } = props
  const { positions, securityDevices, securityDevicesByParent } = useStore()
  const [securedProducts, setSecuredProducts] = useState()
  const [showReassign, setShowReassign] = useState(false)
  const position = positions[positionID]
  const securityDevice = securityDevices[position.securityDeviceId]
  const associatedDevice = securityDevices[securityDevice?.parentId] || securityDevicesByParent[securityDevice?.id]

  // Get the primary device that determines state and contains the radio
  // The primary device that determines state in a puckbase pair is actually the puck, not the base
  let sd = securityDevice
  if (sd?.deviceType == DT.base || sd?.deviceType == DT.fm2Base)
    sd = associatedDevice
  const state = useDeviceStateFor()(sd)

  // Refresh the secured products data whenever the security device changes.
  const refresh = () => {
    if (!sd) setSecuredProducts(null)
    else api.getSecurityDevice(sd.id)
      .then(response => setSecuredProducts(toHash(response?.securedProducts)))
      .catch(()=>toast.error('Could not retrieve secured products'))
  }

  useEffect(() => refresh(), [sd])

  const sendCommand = command => {
    if (!sd) return
    api.sendCommand(command, sd.id)
      .then(()=>toast.success(t('success.request')))
      .catch(()=>toast.error(t('error.request')))
  }

  const wrap = content => (
    <div className='position-modal-comp col'>
      <h1 className='position-name'>{iconFor(state.security)}{position?.name}</h1>
      {content}
    </div>
  )

  // Port Info
  const ports = useMemo(() => {
    if (![DT.cxFlex, DT.cxFlex1, DT.cxFlex4].includes(sd?.deviceType)) return null
    return sd.ports?.filter(p => p.cableType != CableType.PORTNOTSUPPORTED)
  }, [sd])
  const portsInfo = ports?.map(port => {
    const securedProduct = securedProducts?.[port?.securedProductId]
    return (
      <PortCell
        key={port?.address}
        port={port}
        securedProduct={securedProduct}
        securityDevice={sd}
      />
    )
  })

  // Reassignment selector
  if (showReassign) return wrap(<DeviceAssignment position={position} onFinish={()=>setShowReassign(false)} />)

  // Basic device info/serials
  const deviceInfoFor = device => !device ? null : (
    <div>
      <div className='battery-device-container row'>
        <p className='device-type'>{t(`device.type.${camelize(device.deviceType)}`)}</p>
        {device?.deviceType === DT.lock &&
         <div className='row'>
           <i className={`material-icons ${getBatteryStyle(device.batteryPercentage)}`}>{getBatteryIcon(device.batteryPercentage)}</i>
           <p>{device.batteryPercentage > 0 ? `${device.batteryPercentage}%` : 'Unavailable'}</p>
         </div>
        }
      </div>
      <p className='subtitle'>{device.serialNumber}</p>
      {securityDevice?.disarmedBy && securityDevice?.disarmedAt &&
        <p className='subtitle'>{t('device.disarmedAt', { user: securityDevice.disarmedBy, date: toFriendlyDate(securityDevice.disarmedAt), time: toFriendlyTime(securityDevice.disarmedAt) })}</p>
      }
      {securityDevice?.securityStatusUpdatedAt &&
        <p className='subtitle'>{t('device.securityStatusUpdatedAt', { date: toFriendlyDate(securityDevice.securityStatusUpdatedAt), time: toFriendlyTime(securityDevice.securityStatusUpdatedAt) })}</p>
      }
    </div>
  )

  // Health Issues
  const alarmingInfo = state.alarming ? <Issue state={state.alarming} /> : null
  const unhealthyInfo = state.unhealthyReason ? <Issue reason={state.unhealthyReason} ports={sd.unhealthyPorts} /> : null

  // Action Buttons
  let action = 'arm'
  if (sd?.alarming && !sd?.silentAlarming) action = 'silence'
  else if (sd?.armed || sd?.silentAlarming) action = 'disarm'
  const actionBtns = (sd && sd?.deviceType !== DT.lock) ? (
    <div className='row button-row'>
      <Button onClick={()=>sendCommand('identify')}>{t('position.identify')}</Button>
      <Button onClick={()=>sendCommand(action)}>{t(`position.${action}`)}</Button>
    </div>
  ) : null

  return wrap(
    <>
      {alarmingInfo}
      {unhealthyInfo}
      {deviceInfoFor(securityDevice)}
      {deviceInfoFor(associatedDevice)}
      {portsInfo}
      {actionBtns}
      {me?.canEditStore && <Button onClick={()=>setShowReassign(true)}>{securityDevice ? t('position.reassign') : t('position.assign') }</Button>}
    </>
  )
}

const getBatteryIcon = percentage => {
  switch(true) {
  case (percentage <= 5): return 'battery_0_bar'
  case (percentage <= 20): return 'battery_1_bar'
  case (percentage <= 35): return 'battery_2_bar'
  case (percentage <= 50): return 'battery_3_bar'
  case (percentage <= 65): return 'battery_4_bar'
  case (percentage <= 80): return 'battery_5_bar'
  case (percentage <= 94): return 'battery_6_bar'
  case (percentage >= 95): return 'battery_full'
  default: return 'battery_alert'
  }
}

const getBatteryStyle = percentage => {
  switch(true) {
  case (percentage > 0 && percentage <= 20): return 'critical'
  case (percentage > 20 && percentage <= 35): return 'low'
  default: return 'high'
  }
}
