import { useTranslation } from 'react-i18next'
import { CableType } from 'protoModels/mti/store/cable/state_pb'
import { useStore, useMe } from 'global'
import { DeviceType as DT, DeviceState as State } from 'enums'
import { useDeviceStateFor, iconFor } from 'utils'
import { openModal, Button } from 'components'
import { edit } from 'components/zoneEditor'
import PositionModal from 'components/positionModal'
import './styles.scss'

export default function PositionCell(props) {
  const { position: p, movingZone, moveZone } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { fixtures, areas, floors, securityDevices } = useStore()
  const { me } = useMe()
  const fixture = fixtures?.[p?.fixtureId]
  const area = areas?.[fixture?.areaId]
  const floor = floors?.[area?.floorId]

  const securityDevice = securityDevices?.[p.securityDeviceId]
  const state = useDeviceStateFor()(securityDevice)
  let unhealthyState = null
  if (state.unhealthyReason) unhealthyState = State.unhealthy
  if (state.alarming) unhealthyState = state.alarming

  const open = () => {
    if (movingZone) return
    openModal(<PositionModal position={p} />)
  }

  // Ports info for CXFlex devices
  const portsDisplay = ([DT.cxFlex, DT.cxFlex1, DT.cxFlex4].includes(securityDevice?.deviceType)) ?
    <div className='row ports-container'>
      <p className='subtitle'>{t('ports.ports')}</p>
      <div className='row indicators-container'>
        {securityDevice?.ports?.filter(port => port.cableType != CableType.PORTNOTSUPPORTED).map(port => (
          <div key={port.address} className={`indicator ${getPortStyle(port, securityDevice)}`}></div>
        ))}
      </div>
    </div> : null

  const info =
    <div className='info col'>
      <p className="subtitle">
        {Object.entries(floors).length > 1 && `${floor?.name} > `} {`${area?.name} > ${fixture?.name}`}
      </p>
      <p>{p.name}</p>
      {portsDisplay}
    </div>

  // Action buttons
  const actionBtns = me?.canEditStore ?
    <>
      {movingZone === p && <p className='subtitle selected'>{t('storeHierarchy.moving')}</p>}
      {!movingZone && <Button flat onClick={()=>edit('position', p)} icon="edit" />}
      {(!movingZone || movingZone === p) && moveZone && <Button flat icon='unfold_more' onClick={() => movingZone === p ? moveZone(null) : moveZone(p)} />}
    </> : null

  return (
    <div className={`position-cell-comp cell ${movingZone && (p !== movingZone) && 'invalid'}`} onClick={open}>
      {createIcon(state.security, unhealthyState)}
      {info}
      {actionBtns}
    </div>
  )
}

const getPortStyle = (port, securityDevice) => {
  if (port.cableType == CableType.NOCABLECONNECTED) return 'unused'
  if (securityDevice.unhealthyPorts?.includes(port.address)) return 'unhealthy'
  return 'healthy'
}

const createIcon = (securityState, unhealthyState) => (
  <div className='state-icon'>
    {iconFor(securityState)}
    {unhealthyState ? <div className='health-icon'>{iconFor(unhealthyState)}</div> : null}
  </div>
)
