import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { history, useSession, useAPI } from 'global'
import Screen from './screen'

export default function Login(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { teamKey } = props
  const api = useAPI()
  const session = useSession()
  const screen = useRef()
  const [otpToken, setOTPToken] = useState(null)

  const submitLogin = (username, password) => {
    // TODO: change to loading status / show spinner
    api.login(username, password).then(response => {
      session.setAuthentication(response?.authentications?.[0])
      history.replace('/stores')
    }).catch(response => {
      if (response?.status === 401) {
        setOTPToken(response.error?.error?.validationErrors?.authentication?.token)
        return
      }

      const msg = (response?.status >= 400 && response?.status < 500)
        ? t('error.invalidCredentials')
        : `${t('error.login')}: ${response.status}`
      toast.error(msg)
      screen.current.clear()
    }).catch(()=>{})
  }

  const submitOtp = otp => {
    api.otpRequest(otp, otpToken).then(response => {
      session.setAuthentication(response?.authentications?.[0])
      history.replace('/organizations')
    }).catch(()=>{})
  }

  return <Screen ref={screen} onSubmitLogin={submitLogin} onSubmitOtp={submitOtp} showOtp={otpToken} teamKey={teamKey}/>
}
