import { useState, useEffect } from 'react'
import './styles.scss'

export default function LoadingSpinner(props) {
  const { small } = props
  const [visible, setVisible] = useState(false)

  let klass = 'loading-spinner-comp'
  klass += small ? ' small' : ' large'
  if (!visible) klass += ' hidden'

  // Without this timeout, we might see the spinner flash on the
  // screen for a millisecond before rendering the page, which is
  // jarring to the eyes. Feel free to adjust the timeout here.
  useEffect(() => {
    let timeout = setTimeout(() => setVisible(true), 200)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className={klass}>
      <div className='loading-spinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
