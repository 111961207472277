export default function StoreCell(props) {
  const { data: store } = props

  return (
    <div className='store-cell-comp col'>
      <p>{store?.name}</p>
      <div className='col'>
        <p className='subtitle branch-code'>{store?.branchCode}</p>
        <div className='row location-info'>
          <p className='subtitle'>
            {store?.address?.city && <span>{store?.address?.city}</span>}
            {store?.address?.state && <span>{` | ${store?.address?.state}`}</span>}
            {store?.region?.name && <span>{` | ${store?.region?.name}`}</span>}
          </p>
        </div>
      </div>
    </div>
  )
}
