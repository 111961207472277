// source: mti/store/proximity_puck/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.proximity_puck.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.proximity_puck.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.proximity_puck.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.proximity_puck.State.displayName = 'proto.mti.store.proximity_puck.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.proximity_puck.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.proximity_puck.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.proximity_puck.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.proximity_puck.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    puckModel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    puckHardwareVersion: jspb.Message.getFieldWithDefault(msg, 7, ""),
    puckFirmwareVersion: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    identifying: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    lifted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    charging: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    basePowered: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    keyInserted: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    deepSleep: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    puckSwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    smartCableSwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    tetherSwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    armed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    puckAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    smartCableAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    tetherAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    silentAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    usbDevicePresent: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    usbDeviceCharging: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    usbDeviceOverCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    alarming: jspb.Message.getBooleanFieldWithDefault(msg, 24, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.proximity_puck.State}
 */
proto.mti.store.proximity_puck.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.proximity_puck.State;
  return proto.mti.store.proximity_puck.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.proximity_puck.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.proximity_puck.State}
 */
proto.mti.store.proximity_puck.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckHardwareVersion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckFirmwareVersion(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLostCommunication(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIdentifying(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLifted(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCharging(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBasePowered(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyInserted(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeepSleep(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckSwitchOpen(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmartCableSwitchOpen(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTetherSwitchOpen(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArmed(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckAlarming(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmartCableAlarming(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTetherAlarming(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilentAlarming(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsbDevicePresent(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsbDeviceCharging(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsbDeviceOverCurrent(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlarming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.proximity_puck.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.proximity_puck.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.proximity_puck.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.proximity_puck.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPuckModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPuckHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPuckFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLostCommunication();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIdentifying();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLifted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCharging();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getBasePowered();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getKeyInserted();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getDeepSleep();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getPuckSwitchOpen();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSmartCableSwitchOpen();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getTetherSwitchOpen();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getArmed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getPuckAlarming();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getSmartCableAlarming();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getTetherAlarming();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSilentAlarming();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getUsbDevicePresent();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUsbDeviceCharging();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getUsbDeviceOverCurrent();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAlarming();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.proximity_puck.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
*/
proto.mti.store.proximity_puck.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.proximity_puck.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.mti.store.proximity_puck.State.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes state_uuid = 4;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.proximity_puck.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes state_uuid = 4;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.proximity_puck.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.proximity_puck.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.proximity_puck.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
*/
proto.mti.store.proximity_puck.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string puck_model = 6;
 * @return {string}
 */
proto.mti.store.proximity_puck.State.prototype.getPuckModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setPuckModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string puck_hardware_version = 7;
 * @return {string}
 */
proto.mti.store.proximity_puck.State.prototype.getPuckHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setPuckHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string puck_firmware_version = 8;
 * @return {string}
 */
proto.mti.store.proximity_puck.State.prototype.getPuckFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setPuckFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool lost_communication = 9;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool identifying = 10;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getIdentifying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setIdentifying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool lifted = 11;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getLifted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setLifted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool charging = 12;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool base_powered = 13;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getBasePowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setBasePowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool key_inserted = 31;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getKeyInserted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setKeyInserted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool deep_sleep = 32;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getDeepSleep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setDeepSleep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool puck_switch_open = 14;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getPuckSwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setPuckSwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool smart_cable_switch_open = 15;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getSmartCableSwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setSmartCableSwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool tether_switch_open = 16;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getTetherSwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setTetherSwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool armed = 17;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getArmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setArmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool puck_alarming = 18;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getPuckAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setPuckAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool smart_cable_alarming = 19;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getSmartCableAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setSmartCableAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool tether_alarming = 20;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getTetherAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setTetherAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool silent_alarming = 33;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getSilentAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setSilentAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool usb_device_present = 21;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getUsbDevicePresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setUsbDevicePresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool usb_device_charging = 22;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getUsbDeviceCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setUsbDeviceCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool usb_device_over_current = 23;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getUsbDeviceOverCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setUsbDeviceOverCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool alarming = 24;
 * @return {boolean}
 */
proto.mti.store.proximity_puck.State.prototype.getAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.proximity_puck.State} returns this
 */
proto.mti.store.proximity_puck.State.prototype.setAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


goog.object.extend(exports, proto.mti.store.proximity_puck);
