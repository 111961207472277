import { useMemo } from 'react'
import { DeviceType as DT, DeviceState as State } from 'enums'
import { useStore } from 'global'

const securityStateFor = (sd1, sd2) => {
  if (!sd1) return State.unassigned
  if (sd1.deviceType == DT.lock) {
    if (sd1.open) return State.open
    if (sd1.locked) return State.locked
    if (sd1.locked == false) return State.unlocked
  }
  if (sd1.armed == false || sd2?.armed == false) return State.disarmed
  if (sd1.armed || sd2?.armed) return State.armed
  return State.unknown // The above statements _should_ cover all situations, but just in case they don't, return unknown
}

const unhealthyReasonFor = (sd1, sd2) => {
  if (!sd1) return
  if (sd1.healthStatus != 0) return sd1.unhealthyReason || 'unknown'
  if (sd2 && sd2.healthStatus != 0) return sd2.unhealthyReason || 'unknown'
}

const defaultState = { security: State.unassigned }

export function useDeviceStateFor() {
  const { securityDevices, securityDevicesByParent } = useStore()
  return useMemo(() => deviceOrPosition => {
    // If it's a position, get the security device
    if (!deviceOrPosition) return defaultState
    let sd1 = deviceOrPosition
    if (sd1.zoneType == 'position') {
      if (!sd1.securityDeviceId) return defaultState
      sd1 = securityDevices[sd1.securityDeviceId]
    }

    // Get the associated device
    const sd2 = securityDevices?.[sd1?.parentId] || securityDevicesByParent[sd1?.id]

    let alarming
    if (sd1?.silentAlarming || sd2?.silentAlarming) alarming = State.silentAlarming
    if ((sd1?.alarming && !sd1?.silentAlarming) || (sd2?.alarming && !sd2?.silentAlarming))
      alarming = State.alarming

    return {
      security: securityStateFor(sd1, sd2),
      unhealthyReason: unhealthyReasonFor(sd1, sd2),
      alarming,
    }
  }, [securityDevices, securityDevicesByParent])
}

export function iconFor(state) {
  switch (state) {
  case State.armed:
  case State.locked: return <i className='material-icons state-icon-comp green'>lock</i>
  case State.disarmed:
  case State.unlocked:
  case State.open: return <i className='material-icons state-icon-comp yellow'>lock_open</i>
  case State.silentAlarming:
  case State.alarming: return <i className='material-icons state-icon-comp red'>notification_important</i>
  case State.unhealthy: return <i className='material-icons state-icon-comp yellow'>warning</i>
  default: return <i className='material-icons state-icon-comp grey'>question_mark</i>
  }
}
