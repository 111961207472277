import { useState, useEffect } from 'react'
import { useAPI } from 'global'

export default function StoreSelect(props) {
  const { defaultStore, onChange } = props
  const api = useAPI()
  const [stores, setStores] = useState()

  useEffect(() => {
    api.getStores({ per: 1000, per_page: 1000 }).then(response => {
      setStores(response.stores)
    }).catch(() => {})
  }, [])

  // Loading
  if (!stores) return <></>

  return (
    <select
      className='store-select-comp'
      name='store'
      defaultValue={defaultStore?.id}
      onChange={e=>onChange(e.target.value)}
    >
      <option key="all" value="">All Stores</option>
      {stores?.map(s => (
        <option key={s.id} value={s.id}>{s.name}</option>
      ))}
    </select>
  )
}
