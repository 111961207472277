import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useMe } from 'global'
import { Button, confirm } from 'components'

export default function RegionCell(props) {
  const { me } = useMe()
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { data: region, onUpdate } = props
  const onDelete = () => confirm(() => {
    api.destroyRegion(region.id).then(() => {
      toast.success(t('global.success'))
      onUpdate?.()
    }).catch(() => {
      toast.error(t('error.deleteRegion'))
    })
  })
  return (
    <div className='region-cell-comp row'>
      <p className='region-name'>{region?.name}</p>
      {me.canDestroyRegion && <Button className='delete-button' danger flat icon='delete' onClick={onDelete} />}
    </div>
  )
}
