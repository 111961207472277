import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Select, Button } from 'components'
import { DeviceState as State } from 'enums'

// This is a singleton for the timeout used to add a slight delay/buffer when the user is typing
// quickly, so that we don't make too many calls to the API or filter the list too quickly and use
// up CPU.
let timeout

export default function PositionsFilter(props) {
  const { defaultFilters, onFilter, onFinish } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const searchField = useRef()
  const stateField = useRef()

  const filter = () => {
    clearTimeout(timeout)
    onFilter({
      text: searchField.current.value,
      state: stateField.current.value,
    })
  }

  const onSearchTextChanged = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => filter(), 250)
  }

  const clear = () => {
    onFilter({})
    onFinish()
  }

  return (
    <div className='positions-filter-comp col'>
      <TextField
        autoFocus
        ref={searchField}
        defaultValue={defaultFilters.text}
        onChange={onSearchTextChanged}
        label={t('global.search')}
      />
      <Select
        ref={stateField}
        defaultValue={defaultFilters.view}
        onChange={filter}
      >
        <option value={''}>{t('position.filter.showAll')}</option>
        <option value={State.unhealthy}>{t('position.filter.showExceptions')}</option>
        <option value={State.unassigned}>{t('position.filter.showUnassigned')}</option>
      </Select>
      <Button outline onClick={clear}>{t('form.clear')}</Button>
    </div>
  )
}