import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useOrganization } from 'global'
import Screen from './screen'

export default function OrganizationEdit(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { onFinish } = props
  const { setOrganization } = useOrganization()
  const api = useAPI()

  const submit = (orgData, addressData) => {
    const combinedData = {...orgData, address: {...addressData}}

    api.saveOrganization(combinedData).then(response => {
      setOrganization(response?.organizations[0], response?.addresses[0])
      toast.success(t('success.organizationEdit'))
      onFinish?.()
    }).catch(() => {
      toast.error(t('error.organizationEdit'))
    })
  }

  return (
    <Screen onSubmit={submit} onFinish={onFinish} />
  )
}
