import { useTranslation } from 'react-i18next'
import { useAPI, useStore } from 'global'
import { toast } from 'react-toastify'
import { StoreEdit, openModal, closeModal } from 'components'
import Screen from './screen.jsx'

export default function StoreDetails() {
  const { t } = useTranslation(null, { useSuspense: false })
  const { store, address, region } = useStore()

  const api = useAPI()

  const onProvision = hubID => {
    if (!hubID) return toast.error(t('error.provisionMode'))

    api.startProvisionMode(store.id, hubID).then(()=>{
      toast.success(t('success.provisionMode'))
    }).catch(()=>{
      toast.error(t('error.provisionMode'))
    })
  }

  const onEdit = () => openModal(<StoreEdit storeData={{ store, address, region }} onFinish={closeModal} />)

  return (
    <Screen onProvision={onProvision} onEdit={onEdit} />
  )
}
