// data should be an array of arrays, where each child element is a string, number, null or undefined. No objects.
// If an object is passed in, it will display as `INVALID_OBJ` in the csv
export function exportCSV(data, filename) {
  if (!window || !data) return false

  // first compile the contents of the csv file based on the data passed in,
  // as well as common CSV file standards
  const content = compile(data)

  // convert the content to a blob, create a hidden download link with the blob, auto-click the link, then remove the link
  let blob = new Blob([content], { type: `text/csv;charset=utf8;` })
  let link = document.createElement('a')
  link.download = `${filename}.csv`
  link.href = URL.createObjectURL(blob)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const endOfLine = '\r\n'
const byteOrderMark = '\ufeff'

// CSV standards doc: https://www.rfc-editor.org/rfc/rfc4180
function compile(data) {
  let content = byteOrderMark // Some CSV programs expect a byteOrderMark at the beginning of the file.

  // loop over each row and add them as a line to the content
  for (let row of data) {
    let compiledRow = ''
    for (let col of row) {
      let v
      if (col == null) v = 'null'
      else if (col instanceof Date) v = col.toLocaleString()
      else if (typeof col == 'object') v = 'INVALID_OBJ'
      else if (typeof col == 'number' || typeof col == 'string') v = col

      // escape any double quotes in the string by using double-double quotes. (See CSV standards doc above)
      if (typeof v == 'string' && v !== 'null') {
        v = v.replaceAll('"','""')
        v = `"${v}"`
      }

      compiledRow += `${v},`
    }
    compiledRow = compiledRow.slice(0,-1)
    content += `${compiledRow}${endOfLine}`
  }

  return content
}
