import React from 'react'
import history from 'global/history'

export default function MenuItem(props) {
  const navigate = () => {
    props.closeSidebar ? props.closeSidebar() : null
    props.to ? history.push(props.to) : null
  }

  return (
    <button onClick={props.onClick || navigate} className="menu-item-comp">
      <div id="content">
        <i className="material-icons">{props.icon}</i>
        <span>{props.title}</span>
      </div>
      <div id="divider" />
    </button>
  )
}
