import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { camelize } from 'utils'
import { TextField, Button, Select } from 'components'
import './styles.scss'

export default function UsersFilter(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { filters, onFilter, roles } = props
  const { name, employeeID, roleKey, inactive } = filters || {}

  const nameRef = useRef()
  const employeeIDRef = useRef()
  const roleRef = useRef()
  const statusRef = useRef()

  const submit = () => {
    const f = {
      name: nameRef.current.value.trim() || null,
      employeeID: employeeIDRef.current.value.trim() || null,
      roleKey: roleRef.current.value || null,
      inactive: statusRef.current.value == 1,
    }
    onFilter?.(f)
  }

  const clear = () => {
    const f = {
      name: nameRef.current.value = null,
      employeeID: employeeIDRef.current.value = null,
      roleKey: roleRef.current.value = null,
      inactive: statusRef.current.value = 0,
    }
    onFilter?.(f)
  }

  return (
    <div className="users-filter-comp col" >
      <TextField
        ref={nameRef}
        defaultValue={name}
        label={t('user.name')}
      />
      <TextField
        ref={employeeIDRef}
        defaultValue={employeeID}
        label={`${t('user.employeeID')} - ${t('form.mustBeExact')}`} />
      <div>
        <label className='subtitle' htmlFor='role'>{t('user.role')}</label>
        <Select
          id='role'
          ref={roleRef}
          defaultValue={roleKey}>
          <option key='all' value=''>{t('roles.all')}</option>
          {roles?.map(r => (
            <option key={r.key} value={r.key}>{t(`roles.${camelize(r.key)}`)}</option>
          ))}
        </Select>
      </div>
      <div>
        <label className='subtitle' htmlFor='status'>{t('status.status')}</label>
        <Select
          ref={statusRef}
          id='status'
          defaultValue={inactive ? 1 : 0}
        >
          <option key="0" value={0}>{t('user.filter.showActive')}</option>
          <option key="1" value={1}>{t('user.filter.showInactive')}</option>
        </Select>
      </div>
      <div className='button-row row'>
        <Button outline onClick={clear}>{t('form.clear')}</Button>
        <Button onClick={submit}>{t('form.submit')}</Button>
      </div>
    </div>
  )
}
