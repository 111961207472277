// source: mti/store/nxdi/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var mti_store_cable_state_pb = require('../../../mti/store/cable/state_pb.js');
goog.object.extend(proto, mti_store_cable_state_pb);
var mti_store_lock_state_pb = require('../../../mti/store/lock/state_pb.js');
goog.object.extend(proto, mti_store_lock_state_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.nxdi.ShellCableType', null, global);
goog.exportSymbol('proto.mti.store.nxdi.ShellType', null, global);
goog.exportSymbol('proto.mti.store.nxdi.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.nxdi.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.nxdi.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.nxdi.State.displayName = 'proto.mti.store.nxdi.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.nxdi.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.nxdi.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.nxdi.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.nxdi.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    puckSerialNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    baseSerialNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    keySerialNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    keyType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cable0Type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cable1Type: jspb.Message.getFieldWithDefault(msg, 10, 0),
    shellType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    shellCableType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    puckBatteryLevel: jspb.Message.getFieldWithDefault(msg, 13, 0),
    baseBatteryLevel: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cableVbusCurrent: jspb.Message.getFieldWithDefault(msg, 15, 0),
    shellOutputCurrent: jspb.Message.getFieldWithDefault(msg, 16, 0),
    shellOutputVoltage: jspb.Message.getFieldWithDefault(msg, 17, 0),
    shellBatteryTemperature: jspb.Message.getFieldWithDefault(msg, 18, 0),
    shellBatteryChargeCycles: jspb.Message.getFieldWithDefault(msg, 19, 0),
    puckModel: jspb.Message.getFieldWithDefault(msg, 20, ""),
    puckHardwareVersion: jspb.Message.getFieldWithDefault(msg, 21, ""),
    puckFirmwareVersion: jspb.Message.getFieldWithDefault(msg, 22, ""),
    baseModel: jspb.Message.getFieldWithDefault(msg, 23, ""),
    baseHardwareVersion: jspb.Message.getFieldWithDefault(msg, 24, ""),
    baseFirmwareVersion: jspb.Message.getFieldWithDefault(msg, 25, ""),
    puckLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    puckIdentifying: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    puckLifted: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    puckCharging: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    puckPowered: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    puckDeepSleep: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    puckShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    puckTether: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    puckSwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    baseLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    baseCharging: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    basePowered: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    baseShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    baseOutputDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    baseOutputOvercurrent: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    baseButtonLifted: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    shellLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    shellCharging: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    shellPowered: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    shellShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    shellOutputDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
    shellOutputOvercurrent: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
    shellBatterySwollenAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 48, false),
    shellBatteryChargeCycleAlert: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
    armed: jspb.Message.getBooleanFieldWithDefault(msg, 50, false),
    keyInserted: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
    silentAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    puckSwitchAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
    tetherAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
    cable0Alarming: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    cable1Alarming: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
    demoMode: jspb.Message.getBooleanFieldWithDefault(msg, 57, false),
    cable0SwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
    cable1SwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 59, false),
    cableVbusDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 60, false),
    cableVbusFault: jspb.Message.getBooleanFieldWithDefault(msg, 61, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.nxdi.State}
 */
proto.mti.store.nxdi.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.nxdi.State;
  return proto.mti.store.nxdi.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.nxdi.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.nxdi.State}
 */
proto.mti.store.nxdi.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckSerialNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseSerialNumber(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeySerialNumber(value);
      break;
    case 8:
      var value = /** @type {!proto.mti.store.lock.KeyType} */ (reader.readEnum());
      msg.setKeyType(value);
      break;
    case 9:
      var value = /** @type {!proto.mti.store.cable.CableType} */ (reader.readEnum());
      msg.setCable0Type(value);
      break;
    case 10:
      var value = /** @type {!proto.mti.store.cable.CableType} */ (reader.readEnum());
      msg.setCable1Type(value);
      break;
    case 11:
      var value = /** @type {!proto.mti.store.nxdi.ShellType} */ (reader.readEnum());
      msg.setShellType(value);
      break;
    case 12:
      var value = /** @type {!proto.mti.store.nxdi.ShellCableType} */ (reader.readEnum());
      msg.setShellCableType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPuckBatteryLevel(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaseBatteryLevel(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCableVbusCurrent(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShellOutputCurrent(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShellOutputVoltage(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShellBatteryTemperature(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShellBatteryChargeCycles(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckModel(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckHardwareVersion(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckFirmwareVersion(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModel(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseHardwareVersion(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseFirmwareVersion(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckLostCommunication(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckIdentifying(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckLifted(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckCharging(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckPowered(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckDeepSleep(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckShutdown(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckTether(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckSwitchOpen(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseLostCommunication(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseCharging(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBasePowered(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseShutdown(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseOutputDisabled(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseOutputOvercurrent(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseButtonLifted(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellLostCommunication(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellCharging(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellPowered(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellShutdown(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellOutputDisabled(value);
      break;
    case 47:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellOutputOvercurrent(value);
      break;
    case 48:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellBatterySwollenAlarming(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShellBatteryChargeCycleAlert(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArmed(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyInserted(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilentAlarming(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckSwitchAlarming(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTetherAlarming(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCable0Alarming(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCable1Alarming(value);
      break;
    case 57:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDemoMode(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCable0SwitchOpen(value);
      break;
    case 59:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCable1SwitchOpen(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCableVbusDisabled(value);
      break;
    case 61:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCableVbusFault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.nxdi.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.nxdi.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.nxdi.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.nxdi.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPuckSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBaseSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getKeySerialNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getKeyType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCable0Type();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCable1Type();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getShellType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getShellCableType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPuckBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getBaseBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCableVbusCurrent();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getShellOutputCurrent();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getShellOutputVoltage();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getShellBatteryTemperature();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getShellBatteryChargeCycles();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getPuckModel();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPuckHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPuckFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBaseModel();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getBaseHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBaseFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPuckLostCommunication();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getPuckIdentifying();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getPuckLifted();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getPuckCharging();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getPuckPowered();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getPuckDeepSleep();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getPuckShutdown();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getPuckTether();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getPuckSwitchOpen();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getBaseLostCommunication();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getBaseCharging();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getBasePowered();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getBaseShutdown();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getBaseOutputDisabled();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getBaseOutputOvercurrent();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getBaseButtonLifted();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getShellLostCommunication();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getShellCharging();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getShellPowered();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getShellShutdown();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getShellOutputDisabled();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getShellOutputOvercurrent();
  if (f) {
    writer.writeBool(
      47,
      f
    );
  }
  f = message.getShellBatterySwollenAlarming();
  if (f) {
    writer.writeBool(
      48,
      f
    );
  }
  f = message.getShellBatteryChargeCycleAlert();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getArmed();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
  f = message.getKeyInserted();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getSilentAlarming();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getPuckSwitchAlarming();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getTetherAlarming();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getCable0Alarming();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getCable1Alarming();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getDemoMode();
  if (f) {
    writer.writeBool(
      57,
      f
    );
  }
  f = message.getCable0SwitchOpen();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getCable1SwitchOpen();
  if (f) {
    writer.writeBool(
      59,
      f
    );
  }
  f = message.getCableVbusDisabled();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getCableVbusFault();
  if (f) {
    writer.writeBool(
      61,
      f
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.nxdi.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.nxdi.State} returns this
*/
proto.mti.store.nxdi.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string puck_serial_number = 3;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getPuckSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string base_serial_number = 4;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getBaseSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes state_uuid = 5;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.nxdi.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes state_uuid = 5;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.nxdi.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.nxdi.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.nxdi.State} returns this
*/
proto.mti.store.nxdi.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string key_serial_number = 7;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getKeySerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setKeySerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional mti.store.lock.KeyType key_type = 8;
 * @return {!proto.mti.store.lock.KeyType}
 */
proto.mti.store.nxdi.State.prototype.getKeyType = function() {
  return /** @type {!proto.mti.store.lock.KeyType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.mti.store.lock.KeyType} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setKeyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional mti.store.cable.CableType cable_0_type = 9;
 * @return {!proto.mti.store.cable.CableType}
 */
proto.mti.store.nxdi.State.prototype.getCable0Type = function() {
  return /** @type {!proto.mti.store.cable.CableType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.mti.store.cable.CableType} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable0Type = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional mti.store.cable.CableType cable_1_type = 10;
 * @return {!proto.mti.store.cable.CableType}
 */
proto.mti.store.nxdi.State.prototype.getCable1Type = function() {
  return /** @type {!proto.mti.store.cable.CableType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.mti.store.cable.CableType} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable1Type = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional ShellType shell_type = 11;
 * @return {!proto.mti.store.nxdi.ShellType}
 */
proto.mti.store.nxdi.State.prototype.getShellType = function() {
  return /** @type {!proto.mti.store.nxdi.ShellType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mti.store.nxdi.ShellType} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional ShellCableType shell_cable_type = 12;
 * @return {!proto.mti.store.nxdi.ShellCableType}
 */
proto.mti.store.nxdi.State.prototype.getShellCableType = function() {
  return /** @type {!proto.mti.store.nxdi.ShellCableType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.mti.store.nxdi.ShellCableType} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellCableType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional uint32 puck_battery_level = 13;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getPuckBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 base_battery_level = 14;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getBaseBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 cable_vbus_current = 15;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getCableVbusCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCableVbusCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 shell_output_current = 16;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getShellOutputCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellOutputCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 shell_output_voltage = 17;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getShellOutputVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellOutputVoltage = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 shell_battery_temperature = 18;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getShellBatteryTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellBatteryTemperature = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint32 shell_battery_charge_cycles = 19;
 * @return {number}
 */
proto.mti.store.nxdi.State.prototype.getShellBatteryChargeCycles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellBatteryChargeCycles = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string puck_model = 20;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getPuckModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckModel = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string puck_hardware_version = 21;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getPuckHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string puck_firmware_version = 22;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getPuckFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string base_model = 23;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getBaseModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseModel = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string base_hardware_version = 24;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getBaseHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string base_firmware_version = 25;
 * @return {string}
 */
proto.mti.store.nxdi.State.prototype.getBaseFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool puck_lost_communication = 26;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool puck_identifying = 27;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckIdentifying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckIdentifying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool puck_lifted = 28;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckLifted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckLifted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool puck_charging = 29;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool puck_powered = 30;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckPowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckPowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool puck_deep_sleep = 31;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckDeepSleep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckDeepSleep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool puck_shutdown = 32;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool puck_tether = 33;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckTether = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckTether = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool puck_switch_open = 34;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckSwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckSwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool base_lost_communication = 35;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool base_charging = 36;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool base_powered = 37;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBasePowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBasePowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool base_shutdown = 38;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool base_output_disabled = 39;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseOutputDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseOutputDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool base_output_overcurrent = 40;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseOutputOvercurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseOutputOvercurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool base_button_lifted = 41;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getBaseButtonLifted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setBaseButtonLifted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool shell_lost_communication = 42;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool shell_charging = 43;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool shell_powered = 44;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellPowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellPowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional bool shell_shutdown = 45;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool shell_output_disabled = 46;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellOutputDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellOutputDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional bool shell_output_overcurrent = 47;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellOutputOvercurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellOutputOvercurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 47, value);
};


/**
 * optional bool shell_battery_swollen_alarming = 48;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellBatterySwollenAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 48, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellBatterySwollenAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 48, value);
};


/**
 * optional bool shell_battery_charge_cycle_alert = 49;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getShellBatteryChargeCycleAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setShellBatteryChargeCycleAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * optional bool armed = 50;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getArmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setArmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};


/**
 * optional bool key_inserted = 51;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getKeyInserted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setKeyInserted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional bool silent_alarming = 52;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getSilentAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setSilentAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional bool puck_switch_alarming = 53;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getPuckSwitchAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setPuckSwitchAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional bool tether_alarming = 54;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getTetherAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setTetherAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional bool cable_0_alarming = 55;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCable0Alarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable0Alarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional bool cable_1_alarming = 56;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCable1Alarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable1Alarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional bool demo_mode = 57;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getDemoMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 57, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setDemoMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 57, value);
};


/**
 * optional bool cable_0_switch_open = 58;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCable0SwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable0SwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional bool cable_1_switch_open = 59;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCable1SwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 59, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCable1SwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 59, value);
};


/**
 * optional bool cable_vbus_disabled = 60;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCableVbusDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 60, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCableVbusDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional bool cable_vbus_fault = 61;
 * @return {boolean}
 */
proto.mti.store.nxdi.State.prototype.getCableVbusFault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 61, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.nxdi.State} returns this
 */
proto.mti.store.nxdi.State.prototype.setCableVbusFault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 61, value);
};


/**
 * @enum {number}
 */
proto.mti.store.nxdi.ShellType = {
  NOSMARTSHELLCONNECTED: 0,
  SHELL2: 1,
  UNKNOWNSHELLCONNECTED: 255
};

/**
 * @enum {number}
 */
proto.mti.store.nxdi.ShellCableType = {
  NOCABLECONNECTED: 0,
  NOCHIPID425V: 1,
  NOCHIPID845V: 2,
  CHIPID425V: 31,
  CHIPID845V: 32,
  PORTNOTSUPPORTED: 254,
  UNKNOWNCABLECONNECTED: 255
};

goog.object.extend(exports, proto.mti.store.nxdi);
