import { useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMe, useOrganization } from 'global'
import { Button, TextField, SearchableMultiSelect } from 'components'
import { Role } from 'enums'
import './styles.scss'

export default function Screen(props) {
  const { userWithResources, assignableRoles, onSaveUser, onSearchLocations, onClose } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { user } = userWithResources || {}
  const { me } = useMe()
  const { organization: org } = useOrganization()

  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const employeeIDRef = useRef()
  const emailRef = useRef()
  const locationSelect = useRef()

  const [selectedRoleKey, setSelectedRoleKey] = useState(user?.roleKey)
  const roleLevel = useMemo(() => getResourceLevel(selectedRoleKey), [selectedRoleKey])
  const isOrgLevel = roleLevel == 'org'
  const isRegionLevel = roleLevel == 'region'

  const onRoleChanged = e => {
    const newRoleKey = e.target.value
    const ls = locationSelect.current
    if (ls && getResourceLevel(newRoleKey) != roleLevel) ls.value = []
    setSelectedRoleKey(newRoleKey)
  }

  const searchLocations = (text, page) => onSearchLocations(text, page, isRegionLevel)

  const submit = e => {
    e.preventDefault()
    const userData = {
      id: user?.id,
      firstName: firstNameRef?.current?.value.trim(),
      lastName: lastNameRef?.current?.value.trim(),
      employeeIdentifier: employeeIDRef?.current?.value.trim(),
    }
    // If there is an email value (creating a user), then add it to the user data.
    // Otherwise, it doesn't get passed at all to the API.
    const email = emailRef?.current?.value.trim()
    if (email) userData.email = email

    const selectedLocations = isOrgLevel ? [org.id] : locationSelect.current?.value.map(location => location.id)

    onSaveUser(userData, selectedRoleKey, selectedLocations)
  }

  return (
    <div className="user-edit-comp col">
      <h1>{user ? t('user.editUser') : t('user.createUser')}</h1>
      <form onSubmit={submit} className='col'>
        {/* Select Role */}
        {/* Don't display the role select if I'm editing myself, or editing a key user. */}
        {user?.id !== me?.id && (
          <div className='col role-select'>
            <label className="subtitle" htmlFor="role">{t('user.role')} *</label>
            <select
              required
              id="role"
              name="role"
              onChange={onRoleChanged}
              defaultValue={selectedRoleKey}>
              <option value='' hidden>{t('user.selectRole')}</option>
              {getRolesList(user, assignableRoles)?.map(r => (
                <option key={r.key} value={r.key}>{t(`roles.${r.camelizedKey}`)}</option>
              ))}
            </select>
          </div>
        )}
        {!user && selectedRoleKey === Role.keyUser && <p className='subtitle info'>{t('user.keyUserInfo1')}<br />{t('user.keyUserInfo2')}</p>}

        {/* Select Locations */}
        <div className={isOrgLevel ? 'hidden' : ''}>
          <SearchableMultiSelect
            required
            ref={locationSelect}
            onSearch={searchLocations}
            defaultValue={userWithResources?.resources}
            label={t('user.searchLocations')}
          />
        </div>

        {/* Basic User Data */}
        <TextField ref={firstNameRef} label={t('user.firstName')} defaultValue={user?.firstName} required />
        <TextField ref={lastNameRef} label={t('user.lastName')} defaultValue={user?.lastName}  />
        <TextField ref={employeeIDRef} label={t('user.employeeID')} defaultValue={user?.employeeIdentifier} />
        {!user && selectedRoleKey !== Role.keyUser && <TextField ref={emailRef} label={t('user.email')} required={!selectedRoleKey !== Role.keyUser} />}

        <div className='row button-row'>
          <Button outline onClick={onClose}>{t('form.cancel')}</Button>
          <Button type="submit">{t('form.save')}</Button>
        </div>
      </form>
    </div>
  )
}

// helper function that returns a resource level (org, region or store) based on a rolekey
const getResourceLevel = roleKey => {
  if (regionRoles.includes(roleKey)) return 'region'
  if (storeRoles.includes(roleKey)) return 'store'
  return 'org'
}

// Return a list of roles to display.
const getRolesList = (user, roles) => {
  if (!user) return roles

  if (user?.roleKey === Role.keyUser) {
    return roles?.filter(role => role.key === Role.keyUser)
  } else {
    return roles?.filter(role => role.key !== Role.keyUser)
  }
}

const storeRoles = [
  Role.keyUser,
  Role.storeAssociate,
  Role.storeManager,
  Role.storeTechnician,
  Role.thirdPartyAssociate,
]
const regionRoles = [Role.regionalManager]
