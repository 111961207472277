import { useState, useEffect, forwardRef, useRef, useImperativeHandle, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from 'components'
import './styles.scss'

// Note: When using this component with static data/any data,
// ensure each object in the data array has a 'name' property,
// as well as an 'id' property for checking equality.

function SearchableMultiSelect(props, ref) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { onSearch, staticData, defaultValue, label, required } = props

  const listView = useRef()
  const textField = useRef()
  const currentPage = useRef(0)
  const totalPages = useRef(0)

  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState(defaultValue || [])

  // Create a hash of selected ids to reduce the filter logic from N^2 to just 2N.
  const selectedIDs = useMemo(() => {
    let hash = { }
    selectedItems.forEach(i => hash[i.id] = true)
    return hash
  }, [selectedItems])

  const unselectedItems = useMemo(() => {
    return items.filter(i => !selectedIDs[i.id])
  }, [items, selectedIDs])

  // The isCleared bool helps us know if we need to run the query onFocus,
  // or if we can just continue displaying the current items
  const isClosed = useRef(true)
  const close = () => {
    setItems([])
    currentPage.current = 0
    isClosed.current = true
    if (textField.current) textField.current.value = ''
  }

  useImperativeHandle(ref, () => ({
    get value() { return selectedItems },
    set value(v) { setSelectedItems(v) },
  }))

  // We pass the page in, instead of setting the currentPage ref and having this function rely
  // on getting that ref, because of race-conditions between typing in the search field and scolling
  // to the bottom in the results list (which also triggers the search)
  const search = page => {
    let text = textField.current?.value
    if (staticData) {
      // For static data, just filter the results.
      const filteredItems = staticData.filter(item => {
        const value = typeof item === 'string' ? item : item?.name
        return value?.toLowerCase().includes(text?.toLowerCase())
      })
      setItems(filteredItems)
    } else {
      onSearch(text, page).then(response => {
        if (staticData || isClosed.current) return
        const meta = response?.meta || {}
        const newData = response?.[meta.primaryResourceCollection] || []

        totalPages.current = Math.ceil(meta?.total / (meta?.perPage || meta?.per_page)) || 0
        currentPage.current = meta?.page || meta?.currentPage || meta?.current_page || currentPage.current

        if (currentPage.current == 1)
          setItems(newData)
        else
          setItems(prevData => [...(prevData || []), ...newData])
      })
    }
  }

  const searchTimeout = useRef()
  const searchWithDelay = () => {
    isClosed.current = false
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(() => search(1), 450)
  }

  // Setup scroll listener
  useEffect(() => {
    // If we scroll to the bottom and there are more pages, query the next page
    const handleScroll = () => {
      const { scrollTop, scrollHeight, offsetHeight } = listView.current || { }
      if (scrollTop + offsetHeight >= scrollHeight && currentPage.current < totalPages.current) {
        search(currentPage.current + 1)
      }
    }

    listView.current?.addEventListener('scroll', handleScroll)
    return () => listView.current?.removeEventListener('scroll', handleScroll)
  }, [])

  const select = item => {
    if (selectedItems.some(selectedItem => selectedItem.id === item.id)) return
    setSelectedItems(prevItems => [...prevItems, item])
    // re-focus on the search field after selecting a value to prevent clearing the items (and to
    // more quickly allow the user to continue searching for other values)
    textField.current?.focus()
  }

  const remove = item => {
    setSelectedItems(prevItems => prevItems.filter(selectedItem => selectedItem.id !== item.id))
    // re-focus on the search field after selecting a value to prevent clearing the items (and to
    // more quickly allow the user to continue searching for other values)
    if (!isClosed.current) textField.current?.focus()
  }

  const focusTimeout = useRef()
  const onFocus = () => {
    clearTimeout(focusTimeout.current)
    if (isClosed.current) searchWithDelay()
  }

  // onBlur triggers before the click event when selecting an option, so add a timeout to allow the
  // click event to finish firing before clearing the list.
  const onBlur = () => {
    focusTimeout.current = setTimeout(close, 250)
  }

  return (
    <div className='searchable-multiselect-comp col'>
      <div className='selected-items'>
        {selectedItems.map((item, index)=> (
          <div key={typeof item === 'string' ? item : item?.id || item?.name || index} className='selected-location row'>
            <p>{typeof item === 'string' ? item : item?.name}</p>
            <i onClick={()=>remove(item)} className='material-icons'>{'close'}</i>
          </div>
        ))}
      </div>
      <TextField
        ref={textField}
        onChange={searchWithDelay}
        label={required ? (`${label} *` || `${t('global.search')} *`) : (label || t('global.search'))}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete='off'
      />
      <ul ref={listView} className={!items?.length ? 'empty' : 'open'}>
        {unselectedItems?.map((item, index) => (
          <li
            key={typeof item === 'string' ? item : item?.id || item?.name || index}
            onClick={()=>select(item)}>
            {typeof item === 'string' ? item : item?.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default forwardRef(SearchableMultiSelect)
