import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI, useMe } from 'global'
import { KeyType, Role } from 'enums'
import { TextField, Button, SearchableSelect } from 'components'
import './styles.scss'

export default function StoreEditScreen(props) {
  const { storeData = {}, onSubmit, onCancel } = props
  const { store, address, region } = storeData

  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const api = useAPI()

  const name = useRef()
  const branchCode = useRef()
  const timeZoneSelect = useRef()
  const regionSelect = useRef()

  const contactName = useRef()
  const contactEmail = useRef()
  const contactPhone = useRef()

  const rfidCheckbox = useRef()
  const bleCheckbox = useRef()
  const ephemeralCheckbox = useRef()

  const address1 = useRef()
  const address2 = useRef()
  const address3 = useRef()
  const city = useRef()
  const state = useRef()
  const country = useRef()
  const postalCode = useRef()

  // If the ephemeral checkbox is toggled on, make sure to enable BLE as well
  const onEphemeralToggled = () => {
    if (ephemeralCheckbox?.current?.checked && bleCheckbox?.current)
      bleCheckbox.current.checked = true
  }

  // If BLE is toggled off, make sure to disable ephemeral as well
  const onBLEToggled = () => {
    if (!bleCheckbox?.current?.checked && ephemeralCheckbox?.current)
      ephemeralCheckbox.current.checked = false
  }

  const submit = e => {
    e.preventDefault()
    const keyTypes = []
    if (rfidCheckbox?.current?.checked) keyTypes.push(KeyType.rfid)
    if (bleCheckbox?.current?.checked) keyTypes.push(KeyType.ble)
    if (ephemeralCheckbox?.current?.checked) keyTypes.push(KeyType.ephemeral)

    const storePayload = {
      name: name.current.value?.trim(),
      branchCode: branchCode.current.value?.trim(),
      timeZone: timeZoneSelect.current.value,
      regionId: regionSelect.current?.value?.id || null,
      contactName: contactName.current.value?.trim(),
      contactEmail: contactEmail.current.value?.trim(),
      contactPhone: contactPhone.current.value?.trim(),
      allowedKeyTypes: keyTypes,
      address: {
        address1: address1.current.value?.trim(),
        address2: address2.current.value?.trim(),
        address3: address3.current.value?.trim(),
        city: city.current.value?.trim(),
        state: state.current.value?.trim(),
        zip: postalCode.current.value?.trim(),
        country: country.current.value?.trim(),
      },
    }

    if (store?.id) storePayload.id = store?.id

    onSubmit(storePayload)
  }

  const searchRegions = (searchText, pageNumber) => {
    const query = {
      name: searchText,
      page: pageNumber || 1,
    }
    api.getRegions(query).then(response => {
      regionSelect.current.addData(response?.regions, response?.meta)
    }).catch(() => { })
  }

  return (
    <div className='store-edit-comp'>
      <form className='col' onSubmit={submit}>
        <div className='section col'>
          <p className='subheading'>{t('storeDetails.general')}</p>
          <TextField required label={t('storeDetails.name')} ref={name} defaultValue={store?.name} />
          <TextField label={t('storeDetails.branchCode')} ref={branchCode} defaultValue={store?.branchCode} />
          <SearchableSelect
            ref={timeZoneSelect}
            staticData={Intl.supportedValuesOf('timeZone')}
            defaultValue={store?.timeZone}
            label={t('storeDetails.timeZone')}
            required
          />
          <SearchableSelect
            required={me?.roleKey === Role.regionalManager}
            ref={regionSelect}
            onSearch={searchRegions}
            defaultValue={region}
            label={t('storeDetails.region')}
          />
        </div>
        <div className='section col'>
          <p className='subheading'>{t('storeDetails.contact')}</p>
          <TextField required label={t('storeDetails.contactName')} ref={contactName} defaultValue={store?.contactName} />
          <TextField required label={t('storeDetails.contactEmail')} ref={contactEmail} defaultValue={store?.contactEmail} type='email' />
          <TextField label={t('storeDetails.contactPhone')} ref={contactPhone} defaultValue={store?.contactPhone} />
        </div>

        <div className='section col'>
          <p className='subheading'>{t('resource.key', {count: 100})}</p>
          <label htmlFor='key-types' className='subtitle'>{t('organizationDetails.keyAllowedTypes')} *</label>
          <div className='col'>
            <div className='row'>
              <input
                disabled
                checked
                id='rfid'
                type='checkbox'
                ref={rfidCheckbox}
              />
              <label htmlFor='rfid'>{t('key.type.rfid')}</label>
            </div>
            <div className='row'>
              <input
                id='ble'
                type='checkbox'
                ref={bleCheckbox}
                defaultChecked={store?.allowedKeyTypes.includes(KeyType.ble)}
                onChange={onBLEToggled}
              />
              <label htmlFor='ble'>{t('key.type.ble')}</label>
            </div>
            <div className='row'>
              <input
                id='ephemeral'
                type='checkbox'
                ref={ephemeralCheckbox}
                defaultChecked={store?.allowedKeyTypes.includes(KeyType.ephemeral)}
                onChange={onEphemeralToggled}
              />
              <label htmlFor='ephemeral'>{t('key.type.ephemeral')}</label>
            </div>
            <p className='subtitle'>{t('key.ephemeralWarning')}</p>
          </div>
        </div>

        <div className='section col'>
          <p className='subheading'>{t('address.address')}</p>
          <TextField required label={t('address.address1')} ref={address1} defaultValue={address?.address1} />
          <TextField label={t('address.address2')} ref={address2} defaultValue={address?.address2} />
          <TextField label={t('address.address3')} ref={address3} defaultValue={address?.address3} />
          <TextField required label={t('address.city')} ref={city} defaultValue={address?.city}/>
          <TextField label={t('address.state')} ref={state} defaultValue={address?.state} />
          <TextField required label={t('address.postalCode')} ref={postalCode} defaultValue={address?.zip} />
          <TextField required label={t('address.country')} ref={country} defaultValue={address?.country}/>
        </div>

        <div className='row button-row'>
          <Button outline onClick={onCancel}>{t('form.cancel')}</Button>
          <Button type='submit'>{t('form.save')}</Button>
        </div>
      </form>
    </div>
  )
}
