import { useState } from 'react'
import './styles.scss'

// A component to select different tabs so we can render different UI based on what's selected.
export default function TabSelect(props) {
  const { labels, onSelect } = props
  const [selectedIndex, setSelectedIndex] = useState(0)

  const select = index => {
    if (selectedIndex === index) return
    setSelectedIndex(index)
    onSelect(index)
  }

  return (
    <div className='tab-select-comp row'>
      {labels.map((label, index) => (
        <div
          key={index}
          className={`tab-container ${index === selectedIndex ? 'selected' : ''}`}
          onClick={()=>select(index)}
        >
          <p>{label}</p>
        </div>
      ))}
    </div>
  )
}