import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrganization } from 'global'
import { TextField, Button } from 'components'
import { KeyType } from 'enums'
import './styles.scss'

export default function OrganizationEditScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { organization: org, address } = useOrganization()
  const { onSubmit, onFinish } = props

  const name = useRef()
  const contactName = useRef()
  const contactEmail = useRef()
  const contactPhone = useRef()

  const [shouldKeysExpire, setShouldKeysExpire] = useState(org?.keyExpTimeIntervalEnabled)
  const [areBackupsLimited, setAreBackupsLimited] = useState(org?.maxBackupKeys)

  const expirationHrs = useRef()
  const expirationMins = useRef()
  const backupKeysLimit = useRef()
  const rfidCheckbox = useRef()
  const bleCheckbox = useRef()
  const ephemeralCheckbox = useRef()

  const address1 = useRef()
  const address2 = useRef()
  const address3 = useRef()
  const city = useRef()
  const state = useRef()
  const country = useRef()
  const postalCode = useRef()

  // If the ephemeral checkbox is toggled on, make sure to enable BLE as well
  const onEphemeralToggled = () => {
    if (ephemeralCheckbox?.current?.checked && bleCheckbox?.current)
      bleCheckbox.current.checked = true
  }

  // If BLE is toggled off, make sure to disable ephemeral as well
  const onBLEToggled = () => {
    if (!bleCheckbox?.current?.checked && ephemeralCheckbox?.current)
      ephemeralCheckbox.current.checked = false
  }

  const submit = e => {
    e.preventDefault()
    const hours = Number(expirationHrs?.current?.value) || 0
    const minutes = Number(expirationMins?.current?.value) || 0
    const intervalEnabled = shouldKeysExpire && !(hours == 0 && minutes == 0)
    const interval = intervalEnabled ? ((hours * 60) + minutes) * 60 : null
    const keyTypes = []
    if (rfidCheckbox?.current?.checked) keyTypes.push(KeyType.rfid)
    if (bleCheckbox?.current?.checked) keyTypes.push(KeyType.ble)
    if (ephemeralCheckbox?.current?.checked) keyTypes.push(KeyType.ephemeral)

    const orgData = {
      id: org?.id,
      name: name?.current?.value.trim(),
      contactName: contactName?.current?.value.trim(),
      contactEmail: contactEmail?.current?.value.trim(),
      contactPhone: contactPhone?.current?.value.trim(),
      allowedKeyTypes: keyTypes,
      keyExpTimeIntervalEnabled: intervalEnabled,
      keyExpTimeInterval: interval,
      backupKeyEnabled: true,
      maxBackupKeys: backupKeysLimit?.current?.value || null,
      maxBackupKeysLimit: !!backupKeysLimit?.current?.value,
    }

    const addressData = {
      address1: address1.current.value.trim(),
      address2: address2.current.value.trim(),
      address3: address3.current.value.trim(),
      city: city.current.value.trim(),
      state: state.current.value.trim(),
      zip: postalCode.current.value.trim(),
      country: country.current.value.trim(),
    }

    onSubmit(orgData, addressData)
  }

  return (
    <div className='organization-edit-comp'>
      <form className='col' onSubmit={submit}>
        <div className='section col'>
          <p className='subheading'>{t('organizationDetails.general')}</p>
          <TextField required label={t('organizationDetails.name')} ref={name} defaultValue={org?.name}/>
          <TextField disabled label={t('organizationDetails.subdomain')} defaultValue={org?.subdomain} />
        </div>

        <div className='section col'>
          <p className='subheading'>{t('organizationDetails.contact')}</p>
          <TextField required label={t('organizationDetails.contactName')} ref={contactName} defaultValue={org?.contactName} />
          <TextField required label={t('organizationDetails.contactEmail')} ref={contactEmail} defaultValue={org?.contactEmail} type='email' />
          <TextField label={t('organizationDetails.contactPhone')} ref={contactPhone} defaultValue={org?.contactPhone} />
        </div>

        <div className='section col'>
          <p className='subheading'>{t('resource.key', {count: 100})}</p>
          <div className='row'>
            <input
              id='keyExpiration'
              type='checkbox'
              checked={shouldKeysExpire}
              onChange={()=>setShouldKeysExpire(!shouldKeysExpire)} />
            <label htmlFor='keyExpiration'>{t('organizationDetails.keyExpiration')}</label>
          </div>
          {shouldKeysExpire &&
            <div className='row key-exp-container'>
              <TextField
                label={t('organizationEdit.enterHours')}
                ref={expirationHrs}
                defaultValue={Math.floor(org?.keyExpTimeInterval / 3600) || 0}
                type='number'
                min='0' />
              <TextField
                label={t('organizationEdit.enterMinutes')}
                ref={expirationMins}
                defaultValue={Math.floor((org?.keyExpTimeInterval % 3600) / (60)) || 0}
                type='number'
                min='0' />
            </div>
          }
          <div className='row'>
            <input
              id='are-backups-limited'
              type='checkbox'
              checked={areBackupsLimited}
              onChange={()=>setAreBackupsLimited(!areBackupsLimited)} />
            <label htmlFor='are-backups-limited'>{t('organizationDetails.limitBackups')}</label>
          </div>
          {areBackupsLimited && <TextField required label={t('organizationEdit.enterLimit')} ref={backupKeysLimit} defaultValue={org?.maxBackupKeys || 1} type='number' min='1' />}
          <label htmlFor='key-types' className='subtitle'>{t('organizationDetails.keyAllowedTypes')} *</label>
          <div className='col'>
            <div className='row'>
              <input disabled id='rfid' type='checkbox' ref={rfidCheckbox} defaultChecked={org?.allowedKeyTypes.includes(KeyType.rfid)} />
              <label htmlFor='rfid'>{t('key.type.rfid')}</label>
            </div>
            <div className='row'>
              <input id='ble' type='checkbox' ref={bleCheckbox} defaultChecked={org?.allowedKeyTypes.includes(KeyType.ble)} onChange={onBLEToggled} />
              <label htmlFor='ble'>{t('key.type.ble')}</label>
            </div>
            <div className='row'>
              <input id='ephemeral' type='checkbox' ref={ephemeralCheckbox} defaultChecked={org?.allowedKeyTypes.includes(KeyType.ephemeral)} onChange={onEphemeralToggled} />
              <label htmlFor='ephemeral'>{t('key.type.ephemeral')}</label>
            </div>
            <p className='subtitle'>{t('key.ephemeralWarning')}</p>
          </div>
        </div>

        <div className='section col'>
          <p className='subheading'>{t('address.address')}</p>
          <TextField required label={t('address.address1')} ref={address1} defaultValue={address?.address1} />
          <TextField label={t('address.address2')} ref={address2} defaultValue={address?.address2} />
          <TextField label={t('address.address3')} ref={address3} defaultValue={address?.address3} />
          <TextField required label={t('address.city')} ref={city} defaultValue={address?.city}/>
          <TextField label={t('address.state')} ref={state} defaultValue={address?.state} />
          <TextField required label={t('address.postalCode')} ref={postalCode} defaultValue={address?.zip} />
          <TextField required label={t('address.country')} ref={country} defaultValue={address?.country}/>
        </div>
        <div className='row button-row'>
          <Button outline onClick={onFinish}>{t('form.cancel')}</Button>
          <Button type='submit'>{t('form.save')}</Button>
        </div>
      </form>
    </div>
  )
}
