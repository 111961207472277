import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMe, useStore } from 'contexts'
import { openModal, closeModal, Button, ListView, PageHeader } from 'components'
import Filters from './filter'
import StoreSelect from './storeSelect'
import UserCell from './userCell'
import './styles.scss'

export default function UsersScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { store } = useStore()
  const { me } = useMe()
  const { users, pagination, onQuery, onSelect, onCreate, roles } = props

  const filtersRef = useRef({
    page: 1,
    name: null,
    employeeID: null,
    roleKey: null,
    storeID: store?.id,
    inactive: false,
  })

  const onPageChanged = f => {
    filtersRef.current.page = f.page
    onQuery(filtersRef.current)
  }

  const onStoreChanged = storeID => {
    filtersRef.current.storeID = storeID
    onQuery(filtersRef.current)
  }

  const onFiltersChanged = f => {
    const newFilters = { ...filtersRef.current, ...f }
    Object.keys(newFilters).forEach(k => {
      if (newFilters[k] === null) delete newFilters[k]
    })
    filtersRef.current = newFilters
    onQuery(filtersRef.current)
    closeModal()
  }

  const showFilters = () => openModal(
    <Filters filters={filtersRef.current} onFilter={onFiltersChanged} roles={roles} />
  )

  return (
    <div className='users-comp page'>
      <PageHeader title={t('resource.user', {count: 100})}>
        {me?.canEditUser && <Button flat icon='add' onClick={onCreate} />}
        <StoreSelect onChange={onStoreChanged} defaultStore={store} />
        <Button flat icon='filter_alt' onClick={showFilters} />
      </PageHeader>
      <ListView
        className='list'
        data={users}
        onQuery={onPageChanged}
        cell={UserCell}
        meta={pagination}
        onSelect={onSelect}
      />
    </div>
  )
}
