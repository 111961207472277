import { useRef, useImperativeHandle, forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, PasswordForgotRequest, TeamLogin } from 'components'
import logo from 'assets/images/logo.svg'
import './styles.scss'

function Screen(props, ref) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { teamKey } = props
  const usernameField = useRef()
  const passwordField = useRef()
  const otpField = useRef()
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showTeamLogin, setShowTeamLogin] = useState(!!teamKey)

  useImperativeHandle(ref, () => ({
    clear: () => (passwordField.current.value = ''),
  }))

  const submitLogin = event => {
    event.preventDefault() // prevent the form submission from refreshing the page
    return props.onSubmitLogin(usernameField.current.value, passwordField.current.value)
  }

  const submitOtp = event => {
    event.preventDefault()
    props.onSubmitOtp(otpField.current.value)
  }

  return (
    <div className='login-comp page'>
      <div id="brand-logo">
        <img src={logo} alt={t('alt.logo')} />
        <span>{t('brand.mti')}</span>
      </div>
      <div className="card">
        { props.showOtp ? (
          <form onSubmit={submitOtp}>
            <TextField
              autoFocus
              ref={otpField}
              name="otp"
              key="otp"
              label={t('form.enterCode')}
              autoComplete="otp" />
            <Button name="otp-submit" type="submit">{t('form.submit')}</Button>
          </form>
        ) : (
          <form onSubmit={submitLogin}>
            <TextField
              autoFocus
              ref={usernameField}
              name="username"
              label={t('profile.username')}
              autoComplete="username" />

            <TextField
              ref={passwordField}
              name="password"
              type="password"
              label={t('profile.password')}
              autoComplete="current-password" />

            <Button name="login" type="submit">{t('profile.login')}</Button>
          </form>
        )}
      </div>
      <Button flat type="button" className='team-login-btn' onClick={()=>setShowTeamLogin(true)}>{t('oauth.teamLogin')}</Button>
      <Button flat type="button" className='forgot-pass-btn' onClick={()=>setShowForgotPassword(true)}>{t('forgotPassword.forgotPassword')}</Button>
      {showForgotPassword && <PasswordForgotRequest onClose={()=>setShowForgotPassword(false)} />}
      {showTeamLogin && <TeamLogin onClose={()=>setShowTeamLogin(false)} teamKey={teamKey} />}
    </div>
  )
}

export default forwardRef(Screen)
