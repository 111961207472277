import { useState, useEffect } from 'react'
import { useAPI, useMe, history } from 'global'
import {
  PageHeader,
  Button,
  ListView,
  LoadingSpinner,
  RegionEdit,
  openModal,
  closeModal,
} from 'components'

export default function Region(props) {
  const { regionID } = props
  const api = useAPI()
  const { me } = useMe()
  const [region, setRegion] = useState()
  const [stores, setStores] = useState([])

  useEffect(() => {
    api.getRegion(regionID).then(response => {
      setRegion(response.regions[0])
      setStores(response.stores)
    }).catch(() => { })
  }, [])

  const update = newData => {
    closeModal()
    setRegion(newData)
  }
  const selectStore = store => history.push(`/stores/${store.id}`)
  const onEdit = () => openModal(<RegionEdit region={region} onFinish={update} />)

  return region ? (
    <div className='region-comp page'>
      <PageHeader title={region.name}>
        {me.canEditRegion && <Button flat icon='edit' onClick={onEdit} />}
      </PageHeader>
      <ListView data={stores} onSelect={selectStore} />
    </div>
  ) : <LoadingSpinner />
}
