import { useRef } from 'react'
import { useAPI } from 'global'
import { TextField, Button, Modal } from 'components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import './styles.scss'

export default function TeamLogin(props) {
  const {
    teamKey: urlTeamKey,
    onClose,
  } = props
  const api = useAPI()
  const { t } = useTranslation(null, { useSuspense: false })
  const storageTeamKey = localStorage?.getItem('teamKey') || ''
  const initialTeamKey = urlTeamKey || storageTeamKey || ''
  const teamKeyField = useRef()

  const submit = event => {
    event.preventDefault()

    const teamKey = teamKeyField.current?.value
    if (!teamKey) return

    api.getOAuthProvider(teamKey).then(response => {
      localStorage?.setItem('teamKey', teamKey)
      window.location.href = response?.auth_url
    }).catch(() => {
      toast.error(t('error.invalidTeam'))
    })
  }

  return (
    <Modal onClose={onClose}>
      <div className='team-login-comp'>
        <form onSubmit={submit} className='col'>
          <h1>{t('oauth.title')}</h1>
          <TextField
            required
            ref={teamKeyField}
            label={t('oauth.teamName')}
            defaultValue={initialTeamKey}
          />
          <p className='subtitle'>{t('oauth.helpText')}</p>
          <Button type='submit'>{t('form.submit')}</Button>
        </form>
      </div>
    </Modal>
  )
}
