import { useState, useRef } from 'react'
import { useAPI, useStore } from 'global'
import { openModal, closeModal, LoadingSpinner } from 'components'
import { KeyType } from 'enums'
import EditKey from './editKey'
import Screen from './screen'

export default function Keys() {
  const api = useAPI()
  const { store } = useStore()
  const [keys, setKeys] = useState()
  const [pagination, setPagination] = useState()
  const selectedKeyType = useRef()

  // Fetches a page of keys based on queries.
  // Sometimes this is called multiple times due to multipe update callbacks, but we want to limit
  // the call to just refresh once.
  const refreshing = useRef()
  const refresh = () => {
    if (!store) return // You can only fetch keys for a particular store (for now)
    if (refreshing.current) return
    refreshing.current = true

    const { searchTerm } = filtersRef.current
    const query = {
      matching: searchTerm,
      store_id: store?.id,
      'sort[attribute]': 'updated_at',
      'sort[direction]': 'desc',
      include: 'user',
    }

    if (selectedKeyType.current.value === KeyType.rfid) {
      getKeys(query, KeyType.rfid)
    } else {
      getKeys(query, KeyType.ble)
    }
  }

  const getKeys = (query, keyType) => {
    api[keyType === KeyType.rfid ? 'getPhysicalKeys' : 'getVirtualKeys'](query).then(response => {
      refreshing.current = false

      // collate user data
      const users = response.users?.reduce((hash, u) => {
        hash[u?.id] = u
        return hash
      }, {}) || {}

      // collate key data
      const list = response[keyType === KeyType.rfid ? 'physicalKeys' : 'virtualKeys']?.map(k => ({
        ...k,
        user: users?.[k.userId],
      })) || []

      const sorted = list.sort((left, right) => right.status)
      setKeys(sorted)
      setPagination(response?.meta)
    }).catch(e => console.log(e))
  }

  // Store the filters in a reference so we can refer back to them on other page refresh triggers
  const filtersRef = useRef({})
  const onFilter = filters => {
    filtersRef.current = filters
    refresh()
  }

  const onCreate = () => openModal(<EditKey onSuccess={refresh} onFinish={closeModal}/>)
  const onEdit = key => openModal(<EditKey mtiKey={key} onSuccess={refresh} onFinish={closeModal} />)

  if (!store) return <LoadingSpinner />
  return (
    <Screen
      keys={keys}
      onQuery={onFilter}
      onCreate={onCreate}
      onEdit={onEdit}
      onChangeKeyType={refresh}
      selectedKeyType={selectedKeyType}
      pagination={pagination}
    />
  )
}
