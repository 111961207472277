import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMobileAPI, useStore, useMe, history } from 'global'
import { Button, LogWindow } from 'components'
import logo from 'assets/images/logo.svg'
import './styles.scss'

export default function TopNav(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { isMobile } = useMobileAPI()
  const { store } = useStore()
  const { me } = useMe()
  const { onToggleSidebar } = props
  const [showLog, setShowLog] = useState(false)

  return (
    <>
      <div id="top-nav-comp">
        <Button id="mobile-menu-btn" icon="menu" flat onClick={onToggleSidebar} />
        <img id="logo" src={logo} alt={t('alt.logo')} />
        <span id="brand-title">{t('brand.title')}</span>
        {store && <p id="store-name" onClick={()=>history.push(`/stores/${store.id}/details`)}>{store?.name}</p>}
        {me?.isSuperUser && isMobile && <i className='logger-icon material-icons' onClick={()=>setShowLog(!showLog)}>{'adb'}</i>}
      </div>
      {showLog && <LogWindow />}
    </>
  )
}
