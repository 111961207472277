import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMe } from 'global'
import { PageHeader, Button, ListView, openModal, closeModal } from 'components'
import Filters from './filter'
import StoreCell from './storeCell'
import './styles.scss'

export default function StoresScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { stores, pagination, onQuery, onSelect, onCreate: createStore } = props
  const { me } = useMe()

  const filtersRef = useRef({
    page: 1,
    name: null,
    branchCode: null,
    city: null,
    state: null,
  })

  const onPageChanged = f => {
    filtersRef.current.page = f.page
    onQuery(filtersRef.current)
  }

  const onFiltersChanged = f => {
    const newFilters = { ...filtersRef.current, ...f }
    Object.keys(newFilters).forEach(k => {
      if (newFilters[k] === null) delete newFilters[k]
    })
    filtersRef.current = newFilters
    onQuery(filtersRef.current)
    closeModal()
  }

  const showFilters = () => openModal(
    <Filters filters={filtersRef.current} onFilter={onFiltersChanged} />
  )

  return (
    <div className='stores-comp page'>
      <PageHeader title={t('resource.store', {count: 100})}>
        {me?.canCreateStore && <Button flat icon="add" onClick={createStore}/>}
        <Button flat icon='filter_alt' onClick={showFilters} />
      </PageHeader>
      <ListView
        className='list'
        data={stores}
        onQuery={onPageChanged}
        onSelect={onSelect}
        cell={StoreCell}
        meta={pagination}
      />
    </div>
  )
}
