import { useRef } from 'react'
import { TextField, Button } from 'components'
import { useTranslation } from 'react-i18next'

export default function KeyFilters(props) {
  const { filters, onFilter } = props
  const { searchTerm } = filters || {}
  const { t } = useTranslation(null, { useSuspense: false })
  const searchField = useRef()

  const submit = () => {
    onFilter?.({
      searchTerm: searchField.current.value.trim() || null,
    })
  }

  const clear = () => {
    onFilter?.({
      searchTerm: searchField.current.value = null,
    })
  }

  return (
    <div className='key-filters-comp col'>
      <TextField ref={searchField} label={t('key.keySerialOrUser')} defaultValue={searchTerm}/>
      <div className='button-row row'>
        <Button outline onClick={clear}>{t('form.clear')}</Button>
        <Button onClick={submit}>{t('form.submit')}</Button>
      </div>
    </div>
  )
}