import { createContext, useState, useContext } from 'react'
const Context = createContext()

export function OrganizationProvider(props) {
  const [orgData, setOrgData] = useState({})

  // Set the selected org and address
  const setOrganization = (organization, address) => {
    setOrgData({organization, address})
  }

  const value = {
    ...orgData,
    setOrganization,
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useOrganization() {
  return useContext(Context)
}
