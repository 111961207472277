import { useMobileAPI } from 'global'
import './styles.scss'

export default function AppFooter() {
  const mobileAPI = useMobileAPI()
  return (
    <div className='app-footer-comp'>
      {mobileAPI.isMobile && `${mobileAPI.version}-`}
      w{process.env.REACT_APP_VERSION}
    </div>
  )
}
