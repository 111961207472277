import { useTranslation } from 'react-i18next'
import { iconFor } from 'utils'
import { DeviceState as State } from 'enums'
import './styles.scss'

export default function UnhealthyIssue({ state, reason, ports }) {
  state = state || State.unhealthy
  const { t } = useTranslation(null, { useSuspense: false })
  const actionLines = reason ? t(`unhealthy.action.generic.${reason}`).split('\n') : null
  return (
    <div className='unhealthy-issue-comp'>
      <div className='issue-name row'>
        {iconFor(state)}
        <p>{reason ? t(`unhealthy.reason.${reason}`) : t(`device.state.${state}`)}</p>
      </div>
      {ports ? <p className='subtitle'>{t(`unhealthy.ports`, {ports})}</p> : null}
      {actionLines?.map((l, idx) => <p key={idx} className='subtitle'>{l}</p>)}
    </div>
  )
}
