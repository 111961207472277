import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useStore } from 'global'
import { CableType } from 'protoModels/mti/store/cable/state_pb'
import { Button, TextField } from 'components'
import './styles.scss'
export default function PortCell(props) {
  const {
    port,
    securedProduct,
    securityDevice,
  } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { store, updateSecurityDevice } = useStore()
  const api = useAPI()
  const nameField = useRef()
  const manufacturerField = useRef()
  const [editMode, setEditMode] = useState(false)

  const submit = e => {
    e.preventDefault()

    const securedProductData = {
      id: securedProduct?.id,
      storeId: store.id,
      otherManufacturerName: manufacturerField.current.value,
      otherProductName: nameField.current.value,
    }

    const saveAndRefresh = data => {
      api.saveSecuredProduct(data).then(() => {
        api.getSecurityDevice(securityDevice.id).then(response => {
          updateSecurityDevice(response?.securityDevices?.[0])
          setEditMode(false)
          toast.success(t('success.portEdit'))
        })
      }).catch(() => toast.error(t('error.portEdit')))
    }

    // If there's an existing product, just PATCH the fields.
    if (securedProductData.id) {
      saveAndRefresh(securedProductData)
    } else {
      // If it's a new product, POST it first, and then PATCH it to assign the port.
      api.saveSecuredProduct(securedProductData).then(response => {
        const portData = {
          id: response?.securedProducts?.[0].id,
          port: {
            securityDeviceId: securityDevice.id,
            address: port.address,
          },
        }
        saveAndRefresh(portData)
      }).catch(() => toast.error(t('error.portEdit')))
    }
  }

  // When a user unassigns a product, just delete it.
  const destroy = () => {
    if (!securedProduct) return
    api.destroySecuredProduct(securedProduct?.id).then(()=>{
      api.getSecurityDevice(securityDevice.id).then(response => {
        updateSecurityDevice(response?.securityDevices?.[0])
        setEditMode(false)
        toast.success(t('success.portUnassign'))
      })
    }).catch(()=>{
      toast.error(t('error.portUnassign'))
    })
  }

  return (
    <div className={`port-cell-comp ${!editMode && 'cursor'}`} onClick={()=>setEditMode(true)}>
      {!editMode ?
        <div className='col'>
          <div className='port-info row'>
            <div>
              <p className='subtitle'>{t('ports.portAddress', { address: port.address })}</p>
              <p>{securedProduct?.otherProductName || t('ports.unassigned')}</p>
              {securedProduct?.otherManufacturerName && <p className='subtitle'>{securedProduct.otherManufacturerName}</p>}
            </div>
            <div className={`indicator ${getPortStyle(port, securityDevice)}`}></div>
          </div>
        </div>
        :
        <form className='col' onSubmit={submit}>
          <p className='subtitle'>{t('ports.portAddress', { address: port.address })}</p>
          <TextField
            required
            ref={nameField}
            label={t('ports.name')}
            defaultValue={securedProduct?.otherProductName}
          />
          <TextField
            required
            ref={manufacturerField}
            label={t('ports.manufacturer')}
            defaultValue={securedProduct?.otherManufacturerName}
          />
          <div className='col'>
            <div className='button-row row'>
              <Button outline onClick={()=>setEditMode(false)}>{t('form.cancel')}</Button>
              <Button type='submit'>{t('form.save')}</Button>
            </div>
            <Button onClick={destroy}>{t('global.unassign')}</Button>
          </div>
        </form>
      }
    </div>
  )
}

const getPortStyle = (port, securityDevice) => {
  const unhealthyPorts = securityDevice.unhealthyDetail?.match(/\d+/g)?.map(Number)

  if (port.cableType == CableType.NOCABLECONNECTED) return 'unused'
  if (unhealthyPorts?.includes(port.address)) return 'unhealthy'
  return 'healthy'
}
