import React, { useState, useContext, useEffect } from 'react'
import { Role } from 'enums'
import { useSession, useAPI, useOrganization, history } from 'global'
const Context = React.createContext()

export function MeProvider(props) {
  const [me, setMe] = useState()
  const api = useAPI()
  const { token } = useSession()
  const { setOrganization } = useOrganization()

  useEffect(() => {
    if (!token) {
      // when the token is cleared, clear the user as well
      setMe(null)
    } else {
      api.getMe().then(response => {
        setMe(compileUser(response.users[0]))
      }).catch(()=>{})
    }
  }, [token])

  // Whenever the user changes, get the org for that user as well
  useEffect(() => {
    if (me?.hasOrganizations || !me) {
      setOrganization(null)
    } else {
      api.getOrganization({include: 'address'}).then(response => {
        const o = response?.organizations?.[0]
        const a = response?.addresses?.[0]
        setOrganization(o, a)
      }).catch(()=>{})
    }

    // Redirect a multi-org user to the organizations list in case of a refresh.
    if (me?.hasOrganizations) {
      const url = history.location.pathname
      if (url != '/organizations') history.push('/organizations')
    }
  }, [me])

  const value = {
    me,
    setMe: user => setMe(compileUser(user)),
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useMe() {
  return useContext(Context)
}

/*************************************
 * Helper Functions/Consts
 *************************************/

const appProviderRoles = [
  Role.superUser,
  Role.tierOneSupport,
  Role.fieldTechnician,
]

// Store Associates, Store Technicians, and Third-Party Associates do not have access to users.
const userEditRoles = [
  Role.superUser,
  Role.fieldTechnician,
  Role.organizationAdministrator,
  Role.regionalManager,
  Role.storeManager,
  Role.userAdministrator,
]

// T1 Support (System) and Support Analyst (Client) have read-only access.
const userIndexRoles = [
  ...userEditRoles,
  Role.tierOneSupport,
  Role.supportAnalyst,
]

// Any roles that are allowed to put a hub in provisioning mode
const provisionRoles = [
  Role.superUser,
  Role.tierOneSupport,
  Role.fieldTechnician,
  Role.organizationAdministrator,
  Role.regionalManager,
  Role.storeManager,
  Role.storeTechnician,
]

const orgEditRoles = [
  Role.superUser,
  Role.organizationAdministrator,
]

const storeCreateRoles = [
  Role.superUser,
  Role.organizationAdministrator,
  Role.regionalManager,
]

const storeEditRoles = [
  ...storeCreateRoles,
  Role.storeManager,
]

const regionCreateRoles = [
  Role.superUser,
  Role.organizationAdministrator,
]

const regionIndexRoles = [
  ...regionCreateRoles,
  Role.regionalManager,
]

const compileUser = user => {
  if (!user) return user
  return {
    ...user,
    isSuperUser: user.roleKey == Role.superUser,
    hasOrganizations: appProviderRoles.includes(user.roleKey),
    canEditOrg: orgEditRoles.includes(user.roleKey),
    canIndexUsers: userIndexRoles.includes(user.roleKey),
    canEditUser: userEditRoles.includes(user.roleKey),
    canCreateStore: storeCreateRoles.includes(user.roleKey),
    canEditStore: storeEditRoles.includes(user.roleKey),
    canProvision: provisionRoles.includes(user.roleKey),
    canIndexRegions: regionIndexRoles.includes(user.roleKey),
    canCreateRegion: regionCreateRoles.includes(user.roleKey),
    canDestroyRegion: regionCreateRoles.includes(user.roleKey),
    canEditRegion: regionCreateRoles.includes(user.roleKey),
  }
}
