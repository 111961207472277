// import { useMemo } from 'react'
// import { useStore } from 'global'
// import CanvasManager from './canvas'
import HierarchyList from './list'
import './styles.scss'

export default function Hierarchy() {
  // const { areas, geometries } = useStore()
  // const areaGeoms = useMemo(() => Object.values(areas || {}).map(a => ({
  //   ...geometries[a.geometryId],
  //   resourceName: a.name,
  // })), [areas, geometries])

  return (
    <div id="hierarchy-comp">
      <HierarchyList />
      {/* <CanvasManager geometries={areaGeoms} /> */}
    </div>
  )
}
