import { useState } from 'react'
import { history, useAPI, useOrganization} from 'global'
import Screen from './screen'

export default function Organizations() {
  const { setOrganization } = useOrganization()
  const [organizations, setOrganizations] = useState([])
  const [addresses, setAddresses] = useState([])
  const [pagination, setPagination] = useState()
  const api = useAPI()

  // Get organizations and addresses for admin users right away, on the orgs list page.
  const getOrganizations = () => {
    api.getOrganizations({include: 'address', organizationId: ''}).then(response => {
      setOrganizations(response.organizations)
      setAddresses(response.addresses)
      setPagination(response.meta)
    }).catch(()=>{})
  }

  // When an admin selects an org, set the org and the its address in the organization context.
  const selectOrganization = org => {
    const addr = addresses.find(address => address.id === org.addressId) || null
    setOrganization(org, addr)
    history.push(`/stores`)
  }

  return (
    <Screen
      data={organizations}
      meta={pagination}
      onQuery={query=>getOrganizations(query)}
      onSelectOrg={org=>selectOrganization(org)}
    />
  )
}