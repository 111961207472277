// source: mti/store/cable/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.cable.CableType', null, global);
goog.exportSymbol('proto.mti.store.cable.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.cable.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.cable.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.cable.State.displayName = 'proto.mti.store.cable.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.cable.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.cable.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.cable.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.cable.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cableSerialNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeSerialNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    portNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cableType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    modelNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cableVersion: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.cable.State}
 */
proto.mti.store.cable.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.cable.State;
  return proto.mti.store.cable.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.cable.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.cable.State}
 */
proto.mti.store.cable.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCableSerialNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeSerialNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPortNumber(value);
      break;
    case 8:
      var value = /** @type {!proto.mti.store.cable.CableType} */ (reader.readEnum());
      msg.setCableType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCableVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.cable.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.cable.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.cable.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.cable.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCableSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPortNumber();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getCableType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getModelNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCableVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.cable.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.cable.State} returns this
*/
proto.mti.store.cable.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.cable.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.cable.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes state_uuid = 3;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.cable.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes state_uuid = 3;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.cable.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.cable.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.cable.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.cable.State} returns this
*/
proto.mti.store.cable.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.cable.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string cable_serial_number = 5;
 * @return {string}
 */
proto.mti.store.cable.State.prototype.getCableSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setCableSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string node_serial_number = 6;
 * @return {string}
 */
proto.mti.store.cable.State.prototype.getNodeSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setNodeSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 port_number = 7;
 * @return {number}
 */
proto.mti.store.cable.State.prototype.getPortNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setPortNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional CableType cable_type = 8;
 * @return {!proto.mti.store.cable.CableType}
 */
proto.mti.store.cable.State.prototype.getCableType = function() {
  return /** @type {!proto.mti.store.cable.CableType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.mti.store.cable.CableType} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setCableType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string model_number = 9;
 * @return {string}
 */
proto.mti.store.cable.State.prototype.getModelNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setModelNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cable_version = 10;
 * @return {string}
 */
proto.mti.store.cable.State.prototype.getCableVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.cable.State} returns this
 */
proto.mti.store.cable.State.prototype.setCableVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.mti.store.cable.CableType = {
  NOCABLECONNECTED: 0,
  USBC: 1,
  MICROUSB: 2,
  LIGHTNING: 3,
  USBCALARMSENSOR: 4,
  MICROUSBALARMSENSOR: 5,
  FLEXIDISC: 6,
  AUDIOFLEXIDISC: 7,
  WEARABLE: 8,
  LOOP: 9,
  CLIP: 10,
  STYLUS: 11,
  ONESENSOR: 12,
  TWOSENSORS: 13,
  MECHANICALONLY: 14,
  PORTNOTSUPPORTED: 254,
  UNKNOWNCABLECONNECTED: 255
};

goog.object.extend(exports, proto.mti.store.cable);
