// source: mti/store/fm_20/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var mti_store_cable_state_pb = require('../../../mti/store/cable/state_pb.js');
goog.object.extend(proto, mti_store_cable_state_pb);
var mti_store_lock_state_pb = require('../../../mti/store/lock/state_pb.js');
goog.object.extend(proto, mti_store_lock_state_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.fm_20.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.fm_20.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.fm_20.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.fm_20.State.displayName = 'proto.mti.store.fm_20.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.fm_20.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.fm_20.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.fm_20.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.fm_20.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    puckSerialNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    baseSerialNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cableType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    puckBatteryLevel: jspb.Message.getFieldWithDefault(msg, 8, 0),
    baseBatteryLevel: jspb.Message.getFieldWithDefault(msg, 9, 0),
    keySerialNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    keyType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    puckLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    puckIdentifying: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    puckCharging: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    puckPowered: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    puckDeepSleep: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    puckShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    puckLifted: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    tetherDisconnected: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    puckSwitchOpen: jspb.Message.getBooleanFieldWithDefault(msg, 48, false),
    baseLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    baseCharging: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    basePowered: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    baseShutdown: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    baseOutputDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    baseOutputOvercurrent: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    baseButtonLifted: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    baseMotorUnlocked: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    baseMotorStuck: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    puckLatched: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
    armed: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    authorizedUserKeyUsed: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    silentAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    puckSwitchAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    tetherAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    baseLiftAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    cableAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    deviceAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    deviceAltAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    deviceLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    deviceAltLostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    deviceCharging: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    deviceOvercurrent: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    puckModel: jspb.Message.getFieldWithDefault(msg, 42, ""),
    puckHardwareVersion: jspb.Message.getFieldWithDefault(msg, 43, ""),
    puckFirmwareVersion: jspb.Message.getFieldWithDefault(msg, 44, ""),
    baseModel: jspb.Message.getFieldWithDefault(msg, 45, ""),
    baseHardwareVersion: jspb.Message.getFieldWithDefault(msg, 46, ""),
    baseFirmwareVersion: jspb.Message.getFieldWithDefault(msg, 47, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.fm_20.State}
 */
proto.mti.store.fm_20.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.fm_20.State;
  return proto.mti.store.fm_20.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.fm_20.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.fm_20.State}
 */
proto.mti.store.fm_20.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckSerialNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseSerialNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.mti.store.cable.CableType} */ (reader.readEnum());
      msg.setCableType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPuckBatteryLevel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBaseBatteryLevel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeySerialNumber(value);
      break;
    case 11:
      var value = /** @type {!proto.mti.store.lock.KeyType} */ (reader.readEnum());
      msg.setKeyType(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckLostCommunication(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckIdentifying(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckCharging(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckPowered(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckDeepSleep(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckShutdown(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckLifted(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTetherDisconnected(value);
      break;
    case 48:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckSwitchOpen(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseLostCommunication(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseCharging(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBasePowered(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseShutdown(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseOutputDisabled(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseOutputOvercurrent(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseButtonLifted(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseMotorUnlocked(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseMotorStuck(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckLatched(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArmed(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthorizedUserKeyUsed(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilentAlarming(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPuckSwitchAlarming(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTetherAlarming(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBaseLiftAlarming(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCableAlarming(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceAlarming(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceAltAlarming(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceLostCommunication(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceAltLostCommunication(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceCharging(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceOvercurrent(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckModel(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckHardwareVersion(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setPuckFirmwareVersion(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModel(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseHardwareVersion(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseFirmwareVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.fm_20.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.fm_20.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.fm_20.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.fm_20.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPuckSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBaseSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCableType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPuckBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBaseBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getKeySerialNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getKeyType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPuckLostCommunication();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPuckIdentifying();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPuckCharging();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPuckPowered();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPuckDeepSleep();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPuckShutdown();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getPuckLifted();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getTetherDisconnected();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getPuckSwitchOpen();
  if (f) {
    writer.writeBool(
      48,
      f
    );
  }
  f = message.getBaseLostCommunication();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getBaseCharging();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getBasePowered();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getBaseShutdown();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getBaseOutputDisabled();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getBaseOutputOvercurrent();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getBaseButtonLifted();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getBaseMotorUnlocked();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getBaseMotorStuck();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getPuckLatched();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getArmed();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getAuthorizedUserKeyUsed();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getSilentAlarming();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getPuckSwitchAlarming();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getTetherAlarming();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getBaseLiftAlarming();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCableAlarming();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getDeviceAlarming();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getDeviceAltAlarming();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getDeviceLostCommunication();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getDeviceAltLostCommunication();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getDeviceCharging();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getDeviceOvercurrent();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getPuckModel();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getPuckHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getPuckFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getBaseModel();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getBaseHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getBaseFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.fm_20.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.fm_20.State} returns this
*/
proto.mti.store.fm_20.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.fm_20.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes state_uuid = 3;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.fm_20.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes state_uuid = 3;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.fm_20.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.fm_20.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.fm_20.State} returns this
*/
proto.mti.store.fm_20.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string puck_serial_number = 5;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getPuckSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string base_serial_number = 6;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getBaseSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional mti.store.cable.CableType cable_type = 7;
 * @return {!proto.mti.store.cable.CableType}
 */
proto.mti.store.fm_20.State.prototype.getCableType = function() {
  return /** @type {!proto.mti.store.cable.CableType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mti.store.cable.CableType} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setCableType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint32 puck_battery_level = 8;
 * @return {number}
 */
proto.mti.store.fm_20.State.prototype.getPuckBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 base_battery_level = 9;
 * @return {number}
 */
proto.mti.store.fm_20.State.prototype.getBaseBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string key_serial_number = 10;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getKeySerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setKeySerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional mti.store.lock.KeyType key_type = 11;
 * @return {!proto.mti.store.lock.KeyType}
 */
proto.mti.store.fm_20.State.prototype.getKeyType = function() {
  return /** @type {!proto.mti.store.lock.KeyType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mti.store.lock.KeyType} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setKeyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool puck_lost_communication = 12;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool puck_identifying = 13;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckIdentifying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckIdentifying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool puck_charging = 14;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool puck_powered = 15;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckPowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckPowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool puck_deep_sleep = 16;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckDeepSleep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckDeepSleep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool puck_shutdown = 17;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool puck_lifted = 18;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckLifted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckLifted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool tether_disconnected = 19;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getTetherDisconnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setTetherDisconnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool puck_switch_open = 48;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckSwitchOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 48, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckSwitchOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 48, value);
};


/**
 * optional bool base_lost_communication = 20;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool base_charging = 21;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool base_powered = 22;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBasePowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBasePowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool base_shutdown = 23;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseShutdown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseShutdown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool base_output_disabled = 24;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseOutputDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseOutputDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool base_output_overcurrent = 25;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseOutputOvercurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseOutputOvercurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool base_button_lifted = 26;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseButtonLifted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseButtonLifted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool base_motor_unlocked = 27;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseMotorUnlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseMotorUnlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool base_motor_stuck = 28;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseMotorStuck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseMotorStuck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool puck_latched = 49;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckLatched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckLatched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * optional bool armed = 29;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getArmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setArmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool authorized_user_key_used = 30;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getAuthorizedUserKeyUsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setAuthorizedUserKeyUsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool silent_alarming = 31;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getSilentAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setSilentAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool puck_switch_alarming = 32;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getPuckSwitchAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckSwitchAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool tether_alarming = 33;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getTetherAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setTetherAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool base_lift_alarming = 34;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getBaseLiftAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseLiftAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool cable_alarming = 35;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getCableAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setCableAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool device_alarming = 36;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool device_alt_alarming = 37;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceAltAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceAltAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool device_lost_communication = 38;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool device_alt_lost_communication = 39;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceAltLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceAltLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool device_charging = 40;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool device_overcurrent = 41;
 * @return {boolean}
 */
proto.mti.store.fm_20.State.prototype.getDeviceOvercurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setDeviceOvercurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional string puck_model = 42;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getPuckModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckModel = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string puck_hardware_version = 43;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getPuckHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string puck_firmware_version = 44;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getPuckFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setPuckFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string base_model = 45;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getBaseModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseModel = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string base_hardware_version = 46;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getBaseHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string base_firmware_version = 47;
 * @return {string}
 */
proto.mti.store.fm_20.State.prototype.getBaseFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.fm_20.State} returns this
 */
proto.mti.store.fm_20.State.prototype.setBaseFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


goog.object.extend(exports, proto.mti.store.fm_20);
