import { Button, useLog } from 'components'
import './styles.scss'

export default function LogWindow() {
  const { logs, clear } = useLog()

  return (
    <div className='log-window-comp'>
      <div className='logs-container col'>
        {logs?.map((log, index)=>(
          <p key={index}>{log}</p>
        ))}
        <Button className='clear-btn' onClick={clear}>Clear</Button>
      </div>
    </div>
  )
}
