import { useTranslation } from 'react-i18next'
import { useBLE } from 'utils'
import { Button } from 'components'
import './styles.scss'

export default function BLEOnboarding() {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    isBLEEnabled,
    openBLESettings,
    requestPermission,
    isPermissionGranted,
  } = useBLE()

  return (
    <div className='ble-onboarding-comp page col'>
      <h1>{t('ble.onboarding.title')}</h1>
      <ul className='col'>
        <li className='row'>
          <i className='material-icons'>{'bluetooth'}</i>
          <div className='col'>
            <p className='li-main-text'>{t('ble.onboarding.bluetoothMainText')}</p>
            <p className='li-subtext'>{t('ble.onboarding.bluetoothSubtext')}</p>
          </div>
        </li>
        <li className='row'>
          <i className='material-icons'>{'location_on'}</i>
          <div className='col'>
            <p className='li-main-text'>{t('ble.onboarding.locationMainText')}</p>
            <p className='li-subtext'>{t('ble.onboarding.locationSubtext')}</p>
          </div>
        </li>
        <li className='row'>
          <i className='material-icons'>{'lock_open'}</i>
          <div className='col'>
            <p className='li-main-text'>{t('ble.onboarding.nearbyMainText')}</p>
            <p className='li-subtext'>{t('ble.onboarding.nearbySubtext')}</p>
          </div>
        </li>
      </ul>
      <div className='col action-container'>
        {!isPermissionGranted && <Button onClick={requestPermission}>{t('ble.onboarding.setPermissions')}</Button>}
        {!isBLEEnabled && <Button onClick={openBLESettings}>{t('ble.onboarding.openBluetoothSettings')}</Button>}
      </div>
    </div>
  )
}
