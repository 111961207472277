import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { Button, Select, DateField } from 'components'

export default function ReportsFilter(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { startDate, endDate, reportType, onFilter } = props
  const reportTypeField = useRef()
  const startDateField = useRef()
  const endDateField = useRef()
  const onSubmit = () => {
    onFilter(reportTypeField.current.value, startDateField.current.value, endDateField.current.value)
  }

  return (
    <div className='reports-filter-comp'>
      <Select defaultValue={reportType} ref={reportTypeField}>
        <option value='operations'>{t('reports.operations')}</option>
        <option value='health'>{t('reports.health')}</option>
        <option value='marketing'>{t('reports.marketing')}</option>
      </Select>
      <DateField type='date' ref={startDateField} label={t('reports.filter.startDate')} defaultValue={startDate} />
      <DateField type='date' ref={endDateField} label={t('reports.filter.endDate')} defaultValue={endDate} />
      <Button onClick={onSubmit}>{t('form.submit')}</Button>
    </div>
  )
}
