import { useRef } from 'react'
import { Button, TextField, openModal, closeModal, confirm } from 'components'
import { useAPI, useStore } from 'global'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import './styles.scss'

// A simple editor for changing the name of a zone component, or for adding a new zone component
// within the store hierarchy.
export default function ZoneEditor(props) {
  let { type, resource, parent } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { store, saveZoneComponent, refresh, areasByFloor, fixturesByArea } = useStore()
  const api = useAPI()
  const nameRef = useRef()
  const hasChildren = (type == 'area' && fixturesByArea[resource?.id]?.length) || (type == 'floor' && areasByFloor[resource?.id]?.length)

  if (!resource) resource = { storeId: store.id }

  // Floors also require an 'order' number
  if (!resource.id && type == 'floor') resource.order = 1

  if (parent)
    switch (type) {
    case 'area': resource.floorId = parent.id; break
    case 'fixture': resource.areaId = parent.id; break
    case 'position': resource.fixtureId = parent.id; break
    }

  const save = () => {
    resource.name = nameRef.current?.value.trim()

    // This is because fixtures must have a 'type' which determines how they display in the
    // now-deprecated layout.
    if (type == 'fixture' && !resource.id)
      resource.type = 'table'

    api.saveZoneComponent(type, resource).then(response => {
      const savedResource = response[`${type}s`][0]
      saveZoneComponent(type, savedResource)
      toast.success(t('success.saveZone'))
      closeModal()
    }).catch(() => toast.error(t('error.saveZone')))
  }

  const destroy = () => confirm(() => {
    api.destroyZoneComponent(type, resource.id).then(() => {
      toast.success(t('success.deleteZone'))
      closeModal()
      refresh()
    }).catch(() => toast.error(t('error.deleteZone')))
  }, null, t('storeHierarchy.zoneDeleteInfo'))

  return (
    <div className='zone-editor-comp col'>
      <h1>{resource.name
        ? t('storeHierarchy.zoneEdit', { zoneType: t(`storeHierarchy.zoneType.${type}`) })
        : t('storeHierarchy.zoneCreate',{ zoneType: t(`storeHierarchy.zoneType.${type}`) })}
      </h1>
      <TextField
        ref={nameRef}
        label={t('storeHierarchy.zoneName', { zoneType: t(`storeHierarchy.zoneType.${type}`) })}
        defaultValue={resource?.name}
        autoFocus
      />
      {!!resource.id && hasChildren &&
        <p className='subtitle'>{t('storeHierarchy.deleteRestricted')}</p>
      }
      <div className='modal-actions row'>
        <Button onClick={save}>{t('form.save')}</Button>
        {!!resource.id && !hasChildren &&
          <Button danger onClick={destroy}>{t('form.delete')}</Button>
        }
      </div>
    </div>
  )
}

// Just an easy helper function to open a modal with the zone editor
export function edit(type, resource, parent) {
  openModal(<ZoneEditor resource={resource} parent={parent} type={type} />)
}