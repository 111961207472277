import { useState, useEffect, useRef } from 'react'
import { useAPI, useMe } from 'global'
import { openModal, closeModal, UserDetails, UserEdit } from 'components'
import Screen from './screen'

export default function Users() {
  const api = useAPI()
  const { me } = useMe()
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState()
  const [roles, setRoles] = useState()

  // Fetches a page of users based on queries.
  // Sometimes this is called multiple times due to multipe update callbacks, but we want to limit
  // the call to just refresh once.
  const refreshing = useRef()
  const refresh = () => {
    if (refreshing.current) return
    refreshing.current = true
    const { page, name, employeeID, roleKey, storeID, inactive } = filtersRef.current
    const query = {
      page,
      name,
      employee_identifier: employeeID,
      role: roleKey,
      store_id: storeID,
      with_inactive: inactive ? 'only' : false,
    }

    api.getUsers(query).then(response => {
      refreshing.current = false
      setUsers(response?.users)
      setPagination(response?.meta)
    }).catch(() => {})
  }

  // If me changes (because the user edited their profile), refetch the users list in case the
  // current user is in the list and needs refreshing.
  useEffect(refresh, [me])

  // Fetch the filterable list of roles here, rather than every time the filters modal is opened.
  useEffect(() => {
    api.getAssignableRoles().then(response => setRoles(response)).catch(()=>{})
  }, [])

  // Store the filters in a reference so we can refer back to them on other page refresh triggers
  const filtersRef = useRef({})
  const onFilter = filters => {
    filtersRef.current = filters
    refresh()
  }

  const onSelect = user => openModal(
    <UserDetails user={user} onUpdate={refresh} />
  )

  const onCreate = () => openModal(
    <UserEdit onFinish={closeModal} onUpdate={refresh} />
  )

  return (
    <Screen
      users={users}
      onQuery={onFilter}
      onSelect={onSelect}
      onCreate={onCreate}
      pagination={pagination}
      roles={roles}
    />
  )
}
