// deeply translates an object's property names/keys from snake_case to camelCase
// Does NOT translate strings. Only keys.
export function camelizeObject(obj) {
  if (obj == null) return obj
  if (Array.isArray(obj)) return obj

  // Don't camelize strings. Only an object's properties/keys.
  // Use camelize() below if you want to convert a string.
  if (typeof obj == 'string') return obj

  let newObj = { }
  Object.keys(obj).forEach(k => {
    let value = obj[k]
    if (typeof value == 'object')
      value = camelizeObject(value)
    newObj[camelize(k)] = value
  })
  return newObj
}

// Translates a snake_cased string to camelCase
// If you pass in an object, this will act as an alias to the camelizeObject() function above.
export function camelize(str) {
  if (str == null) return str
  if (typeof str == 'object') return camelizeObject(str)
  let words = str.split('_')
  let first = words.shift()
  return first + words.map(w => w[0].toUpperCase() + w.slice(1)).join('')
}
