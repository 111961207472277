import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from '../assets/i18n/en.json'

const i18nOptions = {
  // This is just a useful option to know when you want to debug i18n.
  debug: false,

  resources: {
    en: { translation: english },
  },

  // TODO: I'm not sure if navigator.language is the best form of language detection. The i18next
  // library does have additional plugins we can use for more advanced language detection that
  // we may want to checkout: https://www.i18next.com/overview/plugins-and-utils#language-detector

  // the default language that i18next will try to use
  lng: navigator.language,

  // the fallback is used when the default language fails to load for whatever reason, such as if we
  // don't have that particular language.
  fallbackLng: 'en',
}

i18n.use(initReactI18next)
const Ready = i18n.init(i18nOptions)

// Ready is a Promise that tells us when the language file has finished loading so we don't try to
// translate keys in the app prematurely.
export { Ready }
export default i18n
