import { useState } from 'react'
import { useAPI } from 'global'
import { openModal } from 'components'
import IOTDashboardCell from './iotDashboardCell'
import Screen from './screen'

export default function IOTDashboard() {
  const api = useAPI()
  const [data, setData] = useState([])

  const getIOTReports = query => {
    query.per = -1
    api.getIOTReports(query).then(response => {
      const reports = response?.reports.map(r => {
        const store = response?.stores[r.storeID]
        let row = { name: store.name }

        r.devices.forEach(d => {
          let lodeType = {}
          switch (d.type) {
          case 'ConnectHub': lodeType = 'meshController'; break
          case 'DoorLockController': lodeType = 'doorController'; break
          case 'Pinpad': lodeType = 'pinpad'; break
          }

          // if a device of this type already exists that is online, ignore any new ones
          if (row[lodeType]?.online) return

          row[lodeType] = d
          row[lodeType].type = lodeType
        })

        return row
      })

      setData(reports)
    }).catch(()=>{})
  }

  const select = report => {
    openModal(<IOTDashboardCell data={report} showDetails={true}/>)
  }

  return (
    <Screen
      data={data}
      onQuery={getIOTReports}
      onSelect={select}/>
  )
}