import { useTranslation } from 'react-i18next'
import { PageHeader, ListView } from 'components'

function Organizations(props) {
  const { t } = useTranslation(null, { useSuspense: false })

  return (
    <div className='organizations-comp page'>
      <PageHeader title={t('resource.organization', {count: 100})} />
      <ListView
        data={props.data}
        onQuery={props.onQuery}
        cell={p => p.data.name}
        meta={props.meta}
        onSelect={props.onSelectOrg}
      />
    </div>
  )
}

export default Organizations
