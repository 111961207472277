import { useEffect, useState } from 'react'
import { Router } from '@MTIConnect/sw-react-common'
import { history, useSession } from 'global'
import { StoreProvider } from 'contexts/store'
import { VirtualKeyProvider } from 'contexts/virtualKey'
import { MQTTContextListener } from 'components/mqtt'
import Sidebar from 'components/sidebar'
import TopNav from 'components/topNav'
import Modal from 'components/modalProvider'
import './styles.scss'

const { Outlet } = Router

export default function AuthenticatedLayout() {
  const { token } = useSession()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const authenticatedURL = history.location.pathname !== '/login' &&
    history.location.pathname !== '/admin/login'

  useEffect(() => {
    if (!token && authenticatedURL) history.replace('/login')
  }, [token, authenticatedURL])

  // TODO: show a loading spinner
  if (!token && authenticatedURL) return (<></>)

  // I'm placing two navbars, because the vastly different layout structure between mobile and desktop makes
  // this difficult to place and satisfy the design. The navbar is simple enough at the moment, that there
  // shouldn't be any issues with having two of them in the dom, but if it becomes more complex/performance-heavy
  // later on, we may want to use js to detect page size/resizing and optionally turn on one or the
  // other.

  return (
    <StoreProvider>
      <VirtualKeyProvider>
        <MQTTContextListener />
        <div id="authenticated-layout-comp">
          <TopNav onToggleSidebar={()=>setIsSidebarOpen(!isSidebarOpen)} />
          <div id="main-container">
            <Sidebar isOpen={isSidebarOpen} onClose={()=>setIsSidebarOpen(false)}/>
            <div id="page-container">
              <Outlet />
            </div>
          </div>
        </div>
        <Modal />
      </VirtualKeyProvider>
    </StoreProvider>
  )
}
