import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMe, useOrganization } from 'global'
import MenuItem from './menuItem'
import './styles.scss'

export default function SidebarMenu(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const { organization: org } = useOrganization()

  return (
    <div id="sidebar-menu-comp">
      {me?.hasOrganizations && <MenuItem to="/organizations" icon="dashboard" title={t('resource.organization', {count: 100})} closeSidebar={props.closeSidebar} />}
      {org && (
        <>
          <MenuItem to="/organization/details" icon="corporate_fare" title={org?.name || t('resource.organization')} closeSidebar={props.closeSidebar} />
          {me?.canIndexRegions && <MenuItem to="/regions" icon="location_on" title={t('resource.region', {count: 100})} closeSidebar={props.closeSidebar} />}
          <MenuItem to="/stores" icon="location_on" title={t('resource.store', {count: 100})} closeSidebar={props.closeSidebar} />
          {me?.canIndexUsers && <MenuItem to="/users" icon="people" title={t('resource.user', {count: 100})} closeSidebar={props.closeSidebar} />}
          <MenuItem to="/iot-dashboard" icon="monitor_heart" title={t('iotDashboard.iotDashboard')} closeSidebar={props.closeSidebar} />
        </>
      )}
    </div>
  )
}
