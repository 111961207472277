// source: mti/store/ram/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var mti_store_ram_ram_status_pb = require('../../../mti/store/ram/ram_status_pb.js');
goog.object.extend(proto, mti_store_ram_ram_status_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.ram.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.ram.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.ram.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.ram.State.displayName = 'proto.mti.store.ram.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.ram.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.ram.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.ram.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.ram.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ramStatus: (f = msg.getRamStatus()) && mti_store_ram_ram_status_pb.RamStatus.toObject(includeInstance, f),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.ram.State}
 */
proto.mti.store.ram.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.ram.State;
  return proto.mti.store.ram.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.ram.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.ram.State}
 */
proto.mti.store.ram.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = new mti_store_ram_ram_status_pb.RamStatus;
      reader.readMessage(value,mti_store_ram_ram_status_pb.RamStatus.deserializeBinaryFromReader);
      msg.setRamStatus(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.ram.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.ram.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.ram.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.ram.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRamStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      mti_store_ram_ram_status_pb.RamStatus.serializeBinaryToWriter
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.ram.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.ram.State} returns this
*/
proto.mti.store.ram.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.ram.State} returns this
 */
proto.mti.store.ram.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.ram.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.ram.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.ram.State} returns this
 */
proto.mti.store.ram.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RamStatus ram_status = 3;
 * @return {?proto.mti.store.ram.RamStatus}
 */
proto.mti.store.ram.State.prototype.getRamStatus = function() {
  return /** @type{?proto.mti.store.ram.RamStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_ram_ram_status_pb.RamStatus, 3));
};


/**
 * @param {?proto.mti.store.ram.RamStatus|undefined} value
 * @return {!proto.mti.store.ram.State} returns this
*/
proto.mti.store.ram.State.prototype.setRamStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.ram.State} returns this
 */
proto.mti.store.ram.State.prototype.clearRamStatus = function() {
  return this.setRamStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.ram.State.prototype.hasRamStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes state_uuid = 4;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.ram.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes state_uuid = 4;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.ram.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.ram.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.ram.State} returns this
 */
proto.mti.store.ram.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.ram.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.ram.State} returns this
*/
proto.mti.store.ram.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.ram.State} returns this
 */
proto.mti.store.ram.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.ram.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.mti.store.ram);
