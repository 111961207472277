// source: mti/store/secure_plug/state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../../../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var mti_store_ram_ram_status_pb = require('../../../mti/store/ram/ram_status_pb.js');
goog.object.extend(proto, mti_store_ram_ram_status_pb);
var mti_store_secure_plug_secure_plug_port_status_pb = require('../../../mti/store/secure_plug/secure_plug_port_status_pb.js');
goog.object.extend(proto, mti_store_secure_plug_secure_plug_port_status_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.store.secure_plug.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.store.secure_plug.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.store.secure_plug.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.store.secure_plug.State.displayName = 'proto.mti.store.secure_plug.State';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.store.secure_plug.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.store.secure_plug.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.store.secure_plug.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.secure_plug.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    storeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ramStatus: (f = msg.getRamStatus()) && mti_store_ram_ram_status_pb.RamStatus.toObject(includeInstance, f),
    stateUuid: msg.getStateUuid_asB64(),
    stateUpdatedAt: (f = msg.getStateUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    batteryLevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    identifying: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    charging: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    powered: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    armed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    socketAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    ovcAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    lopAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lostCommunication: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    silentAlarming: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    powerOutEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    ovcPending: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    deepSleep: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    port1: (f = msg.getPort1()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    port2: (f = msg.getPort2()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    port3: (f = msg.getPort3()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    port4: (f = msg.getPort4()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    port5: (f = msg.getPort5()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    port6: (f = msg.getPort6()) && mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.toObject(includeInstance, f),
    model: jspb.Message.getFieldWithDefault(msg, 23, ""),
    hardwareVersion: jspb.Message.getFieldWithDefault(msg, 24, ""),
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.store.secure_plug.State}
 */
proto.mti.store.secure_plug.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.store.secure_plug.State;
  return proto.mti.store.secure_plug.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.store.secure_plug.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.store.secure_plug.State}
 */
proto.mti.store.secure_plug.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 22:
      var value = new mti_store_ram_ram_status_pb.RamStatus;
      reader.readMessage(value,mti_store_ram_ram_status_pb.RamStatus.deserializeBinaryFromReader);
      msg.setRamStatus(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStateUuid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStateUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryLevel(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIdentifying(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCharging(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPowered(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArmed(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSocketAlarming(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOvcAlarming(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLopAlarming(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLostCommunication(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSilentAlarming(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPowerOutEnabled(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOvcPending(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeepSleep(value);
      break;
    case 16:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort1(value);
      break;
    case 17:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort2(value);
      break;
    case 18:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort3(value);
      break;
    case 19:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort4(value);
      break;
    case 20:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort5(value);
      break;
    case 21:
      var value = new mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus;
      reader.readMessage(value,mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.deserializeBinaryFromReader);
      msg.setPort6(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareVersion(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.store.secure_plug.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.store.secure_plug.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.store.secure_plug.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.store.secure_plug.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRamStatus();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      mti_store_ram_ram_status_pb.RamStatus.serializeBinaryToWriter
    );
  }
  f = message.getStateUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getStateUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBatteryLevel();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getIdentifying();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCharging();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPowered();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getArmed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSocketAlarming();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOvcAlarming();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLopAlarming();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLostCommunication();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSilentAlarming();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPowerOutEnabled();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getOvcPending();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getDeepSleep();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getPort1();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getPort2();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getPort3();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getPort4();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getPort5();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getPort6();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus.serializeBinaryToWriter
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional mti.Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.store.secure_plug.State.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 store_id = 2;
 * @return {number}
 */
proto.mti.store.secure_plug.State.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string serial_number = 3;
 * @return {string}
 */
proto.mti.store.secure_plug.State.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional mti.store.ram.RamStatus ram_status = 22;
 * @return {?proto.mti.store.ram.RamStatus}
 */
proto.mti.store.secure_plug.State.prototype.getRamStatus = function() {
  return /** @type{?proto.mti.store.ram.RamStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_ram_ram_status_pb.RamStatus, 22));
};


/**
 * @param {?proto.mti.store.ram.RamStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setRamStatus = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearRamStatus = function() {
  return this.setRamStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasRamStatus = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bytes state_uuid = 4;
 * @return {!(string|Uint8Array)}
 */
proto.mti.store.secure_plug.State.prototype.getStateUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes state_uuid = 4;
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {string}
 */
proto.mti.store.secure_plug.State.prototype.getStateUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStateUuid()));
};


/**
 * optional bytes state_uuid = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStateUuid()`
 * @return {!Uint8Array}
 */
proto.mti.store.secure_plug.State.prototype.getStateUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStateUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setStateUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp state_updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.store.secure_plug.State.prototype.getStateUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setStateUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearStateUpdatedAt = function() {
  return this.setStateUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasStateUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 battery_level = 6;
 * @return {number}
 */
proto.mti.store.secure_plug.State.prototype.getBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool identifying = 7;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getIdentifying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setIdentifying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool charging = 8;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool powered = 9;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getPowered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setPowered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool armed = 10;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getArmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setArmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool socket_alarming = 11;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getSocketAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setSocketAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool ovc_alarming = 12;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getOvcAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setOvcAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool lop_alarming = 13;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getLopAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setLopAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool lost_communication = 14;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getLostCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setLostCommunication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool silent_alarming = 15;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getSilentAlarming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setSilentAlarming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool power_out_enabled = 26;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getPowerOutEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setPowerOutEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool ovc_pending = 27;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getOvcPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setOvcPending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool deep_sleep = 28;
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.getDeepSleep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setDeepSleep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional SecurePlugPortStatus port_1 = 16;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort1 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 16));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort1 = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort1 = function() {
  return this.setPort1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort1 = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SecurePlugPortStatus port_2 = 17;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort2 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 17));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort2 = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort2 = function() {
  return this.setPort2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort2 = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SecurePlugPortStatus port_3 = 18;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort3 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 18));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort3 = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort3 = function() {
  return this.setPort3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort3 = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional SecurePlugPortStatus port_4 = 19;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort4 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 19));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort4 = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort4 = function() {
  return this.setPort4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort4 = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional SecurePlugPortStatus port_5 = 20;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort5 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 20));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort5 = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort5 = function() {
  return this.setPort5(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort5 = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SecurePlugPortStatus port_6 = 21;
 * @return {?proto.mti.store.secure_plug.SecurePlugPortStatus}
 */
proto.mti.store.secure_plug.State.prototype.getPort6 = function() {
  return /** @type{?proto.mti.store.secure_plug.SecurePlugPortStatus} */ (
    jspb.Message.getWrapperField(this, mti_store_secure_plug_secure_plug_port_status_pb.SecurePlugPortStatus, 21));
};


/**
 * @param {?proto.mti.store.secure_plug.SecurePlugPortStatus|undefined} value
 * @return {!proto.mti.store.secure_plug.State} returns this
*/
proto.mti.store.secure_plug.State.prototype.setPort6 = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.clearPort6 = function() {
  return this.setPort6(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.store.secure_plug.State.prototype.hasPort6 = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string model = 23;
 * @return {string}
 */
proto.mti.store.secure_plug.State.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string hardware_version = 24;
 * @return {string}
 */
proto.mti.store.secure_plug.State.prototype.getHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setHardwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string firmware_version = 25;
 * @return {string}
 */
proto.mti.store.secure_plug.State.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.store.secure_plug.State} returns this
 */
proto.mti.store.secure_plug.State.prototype.setFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


goog.object.extend(exports, proto.mti.store.secure_plug);
