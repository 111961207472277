import { useState, useEffect } from 'react'
import { useVirtualKey, useStore } from 'global'
import { useBLE } from 'utils'
import { BLEOnboarding, BLEUnlock, BLEProvision, TabSelect } from 'components'
import './styles.scss'

export default function BLELockManager() {
  const {
    initBLE,
    isBLEEnabled,
    isPermissionGranted,
    initScanner,
    startScan,
    stopScan,
  } = useBLE()
  const { store } = useStore()
  const { createVirtualKey } = useVirtualKey()
  const [isUnlockMode, setIsUnlockMode] = useState(true)
  const labels = ['Unlock Mode', 'Provision Mode'] // TODO: translate these

  useEffect(() => {
    // We want to delay setting up BLE on the mobile device until this component mounts.
    // Otherwise, the iOS app will display a modal prompting to enable Bluetooth on start-up.
    initBLE()
    return stopScan
  }, [])

  // Once requirements are met, validate the key and create a new one if necessary.
  // Then, initialize and start the scanner.
  useEffect(() => {
    if (!isBLEEnabled || !isPermissionGranted) return
    createVirtualKey()
    initScanner()
    startScan()
  }, [isBLEEnabled, isPermissionGranted, store])

  return (
    <>
      {(!isBLEEnabled || !isPermissionGranted)
        ? <BLEOnboarding />
        :
        <div className='ble-lock-manager page col'>
          <TabSelect labels={labels} onSelect={()=>setIsUnlockMode(!isUnlockMode)} />
          {isUnlockMode ? <BLEUnlock /> : <BLEProvision />}
        </div>
      }
    </>
  )
}
