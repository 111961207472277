import { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'components'
import { useSession, useAPI } from 'global'
import './styles.scss'

const idleTimerMs = 480000 // 8 minutes
const logoutTimerMs = 120000 // 2 minutes

export default function IdleTimer() {
  const { t } = useTranslation(null, { useSuspense: false })
  const session = useSession()
  const api = useAPI()
  const [countdown, setCountdown] = useState(logoutTimerMs / 1000)
  const [showModal, setShowModal] = useState(false)
  let idleTimer = useRef()
  let countdownInterval = useRef()

  // ----- Idle Timer -----
  useEffect(() => {
    if (!session.token) return
    startIdleTimer()
    addEventListeners()

    return () => {
      clearIdleTimer()
      removeEventListeners()
    }
  }, [session.token])

  const startIdleTimer = () => {
    clearIdleTimer()
    idleTimer.current = setTimeout(() => setShowModal(true), idleTimerMs)
  }

  const clearIdleTimer = () => clearTimeout(idleTimer.current)

  const addEventListeners = () => {
    document.addEventListener('keypress', startIdleTimer)
    document.addEventListener('mousemove', startIdleTimer)
    document.addEventListener('touchstart', startIdleTimer)
    document.addEventListener('touchmove', startIdleTimer)
  }

  const removeEventListeners = () => {
    document.removeEventListener('keypress', startIdleTimer)
    document.removeEventListener('mousemove', startIdleTimer)
    document.removeEventListener('touchstart', startIdleTimer)
    document.removeEventListener('touchmove', startIdleTimer)
  }

  // ----- Logout Timer -----
  useEffect(() => {
    if (!showModal) return
    countdownInterval.current = setInterval(() => setCountdown(prev => prev - 1), 1000)

    return () => clearInterval(countdownInterval.current)
  }, [showModal])

  useEffect(() => {
    if (countdown <= 0) logout()
  }, [countdown])

  const logout = () => {
    api.logout().catch(()=>{})
    session.setAuthentication(null)
    api.orgID = null
    clearInterval(countdownInterval.current)
    setCountdown(logoutTimerMs / 1000)
    setShowModal(false)
  }

  const continueSession = () => {
    clearInterval(countdownInterval.current)
    setCountdown(logoutTimerMs / 1000)
    startIdleTimer()
    setShowModal(false)
  }

  return showModal &&
    <Modal>
      <div className='idle-timer-comp col'>
        <h2>{t('idleTimer.header', { minutes: Math.floor(idleTimerMs / 60000) })}</h2>
        <p>{t('idleTimer.info', { seconds: countdown })}</p>
        <div className='button-row row'>
          <Button outline onClick={logout}>{t('idleTimer.signOutNow')}</Button>
          <Button onClick={continueSession}>{t('idleTimer.continueSession')}</Button>
        </div>
      </div>
    </Modal>
}
