import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useStore, useAPI, useMe } from 'global'
import { Button, Checkbox, PositionCell } from 'components'
import { edit } from 'components/zoneEditor'
import './styles.scss'

export default function Hierarchy() {
  const [movingZone, setMovingZone] = useState(null)

  return (
    <div className="hierarchy-list-comp">
      <StoreCell />
      <FloorList movingZone={movingZone} moveZone={setMovingZone} />
    </div>
  )
}

function FloorList(props) {
  const { floors } = useStore()
  const { movingZone, moveZone } = props

  const sortedFloors = useMemo(() => {
    return Object.values(floors || {})?.sort((a, b) => a.name.localeCompare(b.name))
  }, [floors])

  return sortedFloors?.map(floor => <FloorCell key={floor.id} floor={floor} movingZone={movingZone} moveZone={moveZone} />)
}

/************************************
 * Different cell components for the store hierarchy listview
 ************************************/

function StoreCell() {
  const { store } = useStore()
  const { me } = useMe()

  return (
    <div className='store-cell cell'>
      <p>{store?.name}</p>
      {me?.canEditStore && <Button flat icon='add' onClick={()=>edit('floor', null, store)} />}
    </div>
  )
}

// Just a helper function to DRY up the move-zone logic
const useSaveZone = setShowChildren => {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { saveZoneComponent } = useStore()

  return zone => {
    const type = zone.zoneType
    api.saveZoneComponent(type, zone).then(response => {
      const savedResource = response[`${type}s`]?.[0]
      setShowChildren?.(true)
      saveZoneComponent(type, savedResource)
      toast.success(t('success.moveZone', { name: savedResource.name }))
    }).catch(() =>
      toast.error(t('error.moveZone', { name: zone.name }))
    )
  }
}

function FloorCell(props) {
  const { floor, movingZone, moveZone } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const { areasByFloor, floors } = useStore()
  const [showAreas, setShowAreas] = useState(Object.keys(floors).length == 1)
  const saveZone = useSaveZone(setShowAreas)
  const isDestination = movingZone?.zoneType == 'area'

  const createArea = () => {
    edit('area', null, floor)
    setShowAreas(true)
  }

  const moveArea = () => {
    if (!isDestination) return
    moveZone(null)
    if (movingZone.floorId == floor.id) return
    saveZone({ ...movingZone, floorId: floor.id })
  }

  const sortedAreas = useMemo(() => {
    return areasByFloor[floor.id]?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [areasByFloor])

  return (
    <>
      <div className={`floor-cell cell ${movingZone && !isDestination && 'invalid'}`} onClick={movingZone ? moveArea : ()=>setShowAreas(!showAreas)}>
        <div className='col'>
          <p>{floor.name}</p>
          <p className='subtitle'>{t('storeHierarchy.floor')}</p>
        </div>
        {me?.canEditStore && !movingZone &&
          <>
            <Button flat icon='edit' onClick={()=>edit('floor', floor)} />
            <Button flat icon='add' onClick={createArea} />
          </>
        }
      </div>
      {showAreas && sortedAreas?.map(area => <AreaCell key={area.id} area={area} movingZone={movingZone} moveZone={moveZone} />)}
    </>
  )
}

function AreaCell(props) {
  const { area, movingZone, moveZone } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const { fixturesByArea, areas } = useStore()
  const [showFixtures, setShowFixtures] = useState(Object.keys(areas).length == 1)
  const isSelected = movingZone === area
  const isDestination = movingZone?.zoneType == 'fixture'
  const saveZone = useSaveZone(setShowFixtures)

  const createFixture = () => {
    edit('fixture', null, area)
    setShowFixtures(true)
  }

  const moveFixture = () => {
    if (!isDestination) return
    moveZone(null)
    if (movingZone.areaId == area.id) return

    // Fixtures used to be able to belong to _either_ an area or directly to the floor.
    // But we removed that, so that fixtures must belong to an area, so the floorId can/should just be null.
    saveZone({ ...movingZone, areaId: area.id, floorId: null })
  }

  const sortedFixtures = useMemo(() => {
    return fixturesByArea[area.id]?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [fixturesByArea])

  const hasFurniture = useMemo(() => sortedFixtures.some(f => f.noninteractive), [sortedFixtures])
  const [showFurniture, setShowFurniture] = useState(false)
  const toggleFurniture = e => setShowFurniture(e.target.checked)
  const isInvalid = movingZone && !isSelected && !isDestination

  return (
    <>
      <div className={`area-cell cell ${isInvalid ? 'invalid' : ''}`} onClick={movingZone ? moveFixture : ()=>setShowFixtures(!showFixtures)}>
        <div className='row'>
          <div className='col'>
            <p>{area.name}</p>
            <p className='subtitle'>{t('storeHierarchy.area')}</p>
          </div>
          {!!me?.canEditStore &&
           <>
             {isSelected && <p className='subtitle selected'>{t('storeHierarchy.moving')}</p>}
             {!movingZone && <Button flat icon='edit' onClick={()=>edit('area', area)} />}
             {!movingZone && <Button flat icon='add' onClick={createFixture} />}
             {(!movingZone || isSelected) && <Button flat icon='unfold_more' onClick={() => movingZone === area ? moveZone(null) : moveZone(area)} />}
           </>
          }
        </div>
        {(showFixtures && hasFurniture) && <Checkbox label={t('storeHierarchy.showNoninteractive')} onChange={toggleFurniture} defaultChecked={showFurniture} />}
      </div>
      {showFixtures && sortedFixtures?.map(f =>
        (f.noninteractive && !showFurniture) ? null : <FixtureCell key={f.id} fixture={f} movingZone={movingZone} moveZone={moveZone} />
      )}
    </>
  )
}

function FixtureCell(props) {
  const { fixture, movingZone, moveZone } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const { me } = useMe()
  const { positionsByFixture } = useStore()
  const isSelected = movingZone === fixture
  const isDestination = movingZone?.fixtureId && movingZone?.fixtureId !== fixture.id && !fixture.noninteractive
  const [showPositions, setShowPositions] = useState(false)
  const saveZone = useSaveZone(setShowPositions)

  const createPosition = () => {
    edit('position', null, fixture)
    setShowPositions(true)
  }

  const movePosition = () => {
    if (!isDestination) return
    moveZone(null)
    if (movingZone.fixtureId == fixture.id) return
    saveZone({ ...movingZone, fixtureId: fixture.id })
  }

  const sortedPositions = useMemo(() => {
    return positionsByFixture[fixture.id]?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [positionsByFixture])

  let klass = 'fixture-cell cell'
  if (movingZone && !isSelected && !isDestination) klass += ' invalid'
  if (fixture.noninteractive) klass += ' furniture'

  return (
    <>
      <div className={klass} onClick={movingZone ? movePosition : ()=>setShowPositions(!showPositions)}>
        <div className='col'>
          <p>{fixture.name}</p>
          <p className='subtitle'>{t(`storeHierarchy.fixture${fixture.noninteractive ? 'Noninteractive' : ''}`)}</p>
        </div>
        {me?.canEditStore &&
          <>
            {isSelected && <p className='subtitle selected'>{t('storeHierarchy.moving')}</p>}
            {!movingZone && <Button flat icon='edit' onClick={()=>edit('fixture', fixture)} />}
            {!movingZone && !fixture.noninteractive && <Button flat icon='add' onClick={createPosition} />}
            {(!movingZone || isSelected) && <Button flat icon='unfold_more' onClick={() => movingZone === fixture ? moveZone(null) : moveZone(fixture)} />}
          </>
        }
      </div>
      {showPositions && sortedPositions?.map(p => <PositionCell key={p.id} position={p} movingZone={movingZone} moveZone={moveZone} />)}
    </>
  )
}
