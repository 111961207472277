import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI } from 'global'
import { useMe } from 'contexts'
import { Role } from 'enums'
import { camelize } from 'utils'
import Screen from './screen'

export default function UserEdit(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { me, setMe } = useMe()
  const { user: baseUser, userWithResources: prefetchedUser, onFinish, onUpdate } = props
  const [userWithResources, setUserWithResources] = useState(prefetchedUser)

  const [assignableRoles, setAssignableRoles] = useState()

  // Get assignable roles on mount.
  useEffect(() => {
    api.getAssignableRoles().then(response => {
      const roles = response?.map(r => ({
        key: r.key,
        camelizedKey: camelize(r.key) || 'unknown',
        name: r.name,
      }))
      setAssignableRoles(roles)
    }).catch(()=>{})

    // Also fetch the user's resources, if we only received a user
    if (!baseUser || prefetchedUser) return
    api.getUser(baseUser.id).then(response => {
      setUserWithResources({
        user: response?.users[0],
        resources: response?.roleResources,
      })
    }).catch(()=>{})
  }, [])

  const saveUser = (u, persona, resourceIDs) => {
    const valid = validate(t, u, persona, resourceIDs)
    if (!valid) return
    api.saveUser(u, persona, resourceIDs).then(response => {
      const user = response?.users[0]
      toast.success(t('success.user'))
      const newUserWithResources = {
        user,
        resources: response?.roleResources,
      }
      if (user?.id == me?.id) { setMe?.(user) }
      setUserWithResources(newUserWithResources)
      onUpdate?.(newUserWithResources)
      onFinish?.(newUserWithResources)
    }).catch(response=>{
      if (response.status === 400 || response.status === 500)
        toast.error(t('error.invalidUser'))
    })
  }

  const searchLocations = (searchText, pageNumber, isRegions = false) => {
    const query = {
      name: searchText,
      page: pageNumber || 1,
    }
    const method = isRegions ? 'getRegions' : 'getStores'
    return new Promise(resolve => {
      api[method](query).then(response => resolve(response))
        .catch(()=>{})
    })
  }

  // If we're still loading things, then wait to display the screen.
  if (!assignableRoles || (baseUser && !userWithResources)) return <p>Loading...</p>

  return (
    <Screen
      userWithResources={userWithResources}
      assignableRoles={assignableRoles}
      onSaveUser={saveUser}
      onClose={()=>onFinish?.()}
      onSearchLocations={searchLocations}
    />
  )
}

const validate = (t, user, persona, resourceIDs) => {
  const errors = []
  if (!user.firstName)
    errors.push(t('error.firstNameRequired'))

  if (!user.id && !user.email && persona !== Role.keyUser)
    errors.push(t('error.emailRequired'))

  if (!persona) errors.push(t('error.roleRequired'))
  if (!resourceIDs?.length) errors.push(t('error.locationRequired'))

  const valid = !(errors?.length > 0)
  if (!valid)
    toast.error(errors.join(' '))
  return valid
}
