import { useTranslation } from 'react-i18next'
import { useBLE } from 'utils'
import { Button, LoadingSpinner } from 'components'
import keycard from 'assets/images/keycard.svg'
import './styles.scss'

export const State = {
  locked: 'locked',
  loading: 'loading',
  unlocked: 'unlocked',
}

export default function BLEUnlockScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { deviceSerialNumber: serialNumber } = useBLE()
  const {
    position,
    onUnlock,
    onDisconnect,
    state,
  } = props

  return (
    <div className='ble-unlock-comp col'>
      <img src={keycard} />
      <div className='content-container col'>

        {/* Scanning or Connected */}
        {serialNumber ?
          <div className='main-content col'>
            <h2>{position?.name || t('ble.unassignedLock')}</h2>
            <p className='subtitle'>{serialNumber}</p>
          </div>
          :  <p className='scanning'>{t('ble.scanning')}</p>
        }

        {state == State.loading && <LoadingSpinner />}
        {state == State.unlocked && <p>{t('ble.unlock.success')}</p>}
      </div>

      {/* Action Buttons */}
      {serialNumber && state == State.locked &&
        <div className='button-row row'>
          <Button onClick={onUnlock}>{t('ble.unlock.unlock')}</Button>
          <Button outline onClick={onDisconnect}>{t('ble.disconnect')}</Button>
        </div>
      }
    </div>
  )
}
