import { useTranslation } from 'react-i18next'
import { useAPI } from 'global'
import { useMe } from 'contexts'
import { camelize } from 'utils'
import { Button, confirm } from 'components'
import { toast } from 'react-toastify'

export default function UserCell(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { me } = useMe()
  const { data: user, onUpdate } = props

  const deactivateUser = () => confirm(() => {
    api.deactivateUser(user.id).then(() => onUpdate?.()).catch(()=>{
      toast.error(t('error.userDeactivate'))
    })
  })
  const activateUser = () => api.activateUser(user.id).then(() => onUpdate?.()).catch(()=>{
    toast.error(t('error.userActivate'))
  })

  return (
    <div className='user-cell-comp'>
      <div className='info-container'>
        <p className="name">{`${user?.firstName} ${user?.lastName}`}</p>
        <p className="subtitle">{t(`roles.${camelize(user?.roleKey)}`)}</p>
        <p className="subtitle">{user?.employeeIdentifier}</p>
      </div>
      {user?.id !== me?.id && user?.status !== 'inactive' && me?.canEditUser &&
       <Button id="delete-btn" flat danger icon="delete" onClick={deactivateUser}/>
      }
      {user?.id !== me?.id && user?.status === 'inactive' && me?.canEditUser &&
       <Button icon="person_add" flat highlight onClick={activateUser}/>
      }
    </div>
  )
}
