import { useRef, useImperativeHandle, forwardRef, useId } from 'react'
import './styles.scss'

// const toTimestamp = value => (new Date(value)).getTime()

function DateField(props, ref) {
  const inputID = useId()
  const field = useRef()
  const { label, onChange, ...inputProps } = props

  useImperativeHandle(ref, ()=>({
    // get value() { return toTimestamp(field.current.value) },
    get value() { return field.current.value },
    set value(v) { field.current.value = v },
  }))

  // const onDateChange = d => onChange?.(toTimestamp(d))
  const onDateChange = d => onChange?.(d)

  return (
    <label className="date-field-comp" htmlFor={inputID}>
      <span>{label}</span>
      <input
        ref={field}
        id={inputID}
        name={label}
        type="datetime-local"
        { ...inputProps }
        onChange={e=>onDateChange(e.target.value)} />
    </label>
  )
}

export default forwardRef(DateField)
