import { useTranslation } from 'react-i18next'
import { useMe } from 'global'
import { PageHeader, Button, ListView } from 'components'
import RegionCell from './regionCell'
import './styles.scss'

export default function RegionsScreen(props) {
  const { me } = useMe()
  const { t } = useTranslation(null, { useSuspense: false })
  const { regions, meta, onQuery, onSelect, onCreate } = props
  return (
    <div className='regions-comp page'>
      <PageHeader title={t('resource.region', { count: 100 })}>
        {me?.canCreateRegion && <Button flat icon='add' onClick={onCreate} />}
      </PageHeader>
      <ListView
        className='list'
        data={regions}
        meta={meta}
        onQuery={onQuery}
        onSelect={onSelect}
        cell={RegionCell}
      />
    </div>
  )
}
