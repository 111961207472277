import { useMemo, useState, useEffect, useRef } from 'react'
import { useStore } from 'global'
import { DeviceCell, PageHeader, Button, openModal, closeModal, LoadingSpinner } from 'components'
import { DeviceState as State } from 'enums'
import DeviceFilters from './filters'
import './styles.scss'

export default function MeshDevices() {
  const { securityDevices, securityDevicesByParent, positionsBySecurityDevice, store } = useStore()

  const devices = useMemo(() => {
    return Object.values(securityDevices || {})
      .filter(sd => !sd.parentId)
      .sort(sortByState)
  }, [securityDevices])

  const [filteredDevices, setFilteredDevices ] = useState(devices)
  const [isFiltered, setIsFiltered] = useState(false)

  const filtersRef = useRef({
    status: '',
    serial: '',
    deviceType: '',
    sortBy: '',
  })

  useEffect(() => {
    if (!devices) return
    applyFilters(filtersRef.current)
  }, [devices])

  const applyFilters = filters => {
    if (filters) filtersRef.current = filters
    const { status, serial, deviceType, sortBy } = filtersRef.current

    setIsFiltered(!!(status || serial || deviceType || sortBy))

    const matchesStatus = device => !status || (status == 'assigned' && !!positionsBySecurityDevice[device.id]) || (status == 'unassigned' && !positionsBySecurityDevice[device.id])
    const matchesDeviceType = device => !deviceType || device.deviceType == deviceType
    const matchesSerial = device => {
      const associatedDevice = securityDevices[device.parentId] || securityDevicesByParent[device.id]
      return !serial
        || device.serialNumber.includes(serial.toUpperCase())
        || associatedDevice?.serialNumber.includes(serial.toUpperCase())
    }

    let filtered = devices.filter(device => {
      return matchesStatus(device) && matchesDeviceType(device) && matchesSerial(device)
    })

    filtered = filtered.sort((a, b) => {
      switch (sortBy) {
      case 'serialNumber':
        return a.serialNumber.localeCompare(b.serialNumber)
      case 'positionName':
        return sortByPositionName(positionsBySecurityDevice?.[a.id], positionsBySecurityDevice?.[b.id])
      case 'updatedAt':
        return new Date(a.updatedAt) - new Date(b.updatedAt)
      case 'createdAt':
        return new Date(a.createdAt) - new Date(b.createdAt)
      default:
        return sortByState(a, b)
      }
    })

    setFilteredDevices(filtered)
  }

  const openFilters = () => openModal(
    <DeviceFilters
      filters={filtersRef.current}
      onFilter={applyFilters}
      devices={devices}
      onFinish={closeModal}
    />
  )

  if (!store) return <LoadingSpinner />
  return (
    <div className='device-list-comp'>
      <PageHeader title='Devices'>
        <Button flat isActive={isFiltered} icon='filter_alt' onClick={openFilters}/>
      </PageHeader>
      <div>
        {filteredDevices?.map(s => {
          const position = positionsBySecurityDevice[s.id] || positionsBySecurityDevice[s.parentId]
          const associated = securityDevices[s.parentId]
          return <DeviceCell key={s.id} securityDevice={s} associated={associated} position={position}/>
        })}
      </div>
    </div>
  )
}

const priorities = [
  State.alarming,
  State.unhealthy,
  State.disarmed,
  State.unknown,
  State.armed,
  State.unassigned,
].reverse() // It's easier to read when the higher priority things are near the top of the list.

function priorityFor(device) {
  return priorities.indexOf(device.state)
}

function sortByState(p1, p2) {
  const ld = priorityFor(p1)
  const rd = priorityFor(p2)
  if (ld > rd) return -1
  if (rd > ld) return 1
  if (p1.serialNumber < p2.serialNumber) return -1
  if (p1.serialNumber > p2.serialNumber) return 1
  return 0
}

function sortByPositionName(p1, p2) {
  if (p1 && !p2) return -1
  if (!p1 && p2) return 1
  return (p1?.name || '').localeCompare(p2?.name || '')
}
