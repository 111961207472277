import { useState, useMemo } from 'react'
import { useStore } from 'global'
import { openModal, closeModal } from 'components'
import { DeviceState as State } from 'enums'
import { useDeviceStateFor } from 'utils'
import Filter from './filter'
import Screen from './screen'

export default function Positions() {
  const [filters, setFilters] = useState({})
  const { positions, fixtures, areas, floors } = useStore()
  const stateFor = useDeviceStateFor()
  const positionSort = createPositionSort(stateFor)

  const filteredList = useMemo(() => {
    const text = filters.text?.toLowerCase()
    const state = filters.state
    if (!text && !state) return Object.values(positions || {})

    return Object.values(positions || {})?.filter(p => {
      const fixture = fixtures?.[p?.fixtureId]
      const area = areas?.[fixture?.areaId]
      const floor = floors?.[area?.floorId]
      const matchesText =
        !text
        || p.name?.toLowerCase().includes(text)
        || fixture?.name?.toLowerCase().includes(text)
        || area?.name?.toLowerCase().includes(text)
        || floor?.name?.toLowerCase().includes(text)

      const pState = stateFor(p)
      const matchesState = !state
        || filters.state === State.unhealthy && (pState.unhealthyReason || pState.alarming)
        || filters.state === State.unassigned && !p.securityDeviceId

      return matchesText && matchesState
    })
  }, [positions, filters, fixtures, stateFor])

  const sortedList = useMemo(() => {
    return filteredList ? filteredList.sort(positionSort) : []
  }, [filteredList, stateFor])

  const openFilter = () => openModal(<Filter defaultFilters={filters} onFilter={setFilters} onFinish={closeModal} />)

  return (
    <Screen isFiltered={!!(filters?.state || filters?.text)} positions={sortedList} onFilter={openFilter} />
  )
}

const priorities = [
  State.alarming,
  State.silentAlarming,
  State.unhealthy,
  State.disarmed,
  State.open,
  State.unlocked,
  State.unknown,
  State.locked,
  State.armed,
  State.unassigned,
].reverse() // It's easier to read when the higher priority things are near the top of the list.

const simplify = state => {
  if (state.alarming) return state.alarming
  if (state.unhealthyReason) return State.unhealthy
  return state.security
}

const createPositionSort = stateFor => (left, right) => {
  const lp = priorities.indexOf(simplify(stateFor(left)))
  const rp = priorities.indexOf(simplify(stateFor(right)))
  if (lp > rp) return -1
  if (rp > lp) return 1
  if (left.name < right.name) return -1
  if (right.name > left.name) return 1
  return 0
}
