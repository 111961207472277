import React, { useState, useContext } from 'react'
const Context = React.createContext()
const authKey = 'auth'

// export the provider for testing
export const CustomProvider = Context.Provider

export function SessionProvider(props) {
  const existingAuth = JSON.parse(sessionStorage.getItem(authKey))
  const [authentication, setAuthentication] = useState(existingAuth)

  const setAuthProxy = auth => {
    if (auth)
      sessionStorage.setItem(authKey, JSON.stringify(auth))
    else
      sessionStorage.removeItem(authKey)
    setAuthentication(auth)
  }

  const value = {
    tokenID: authentication?.tokenId,
    token: authentication?.token,
    setAuthentication: setAuthProxy,
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export default function useSession() {
  return useContext(Context)
}
