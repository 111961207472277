import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSession, useAPI, useMe, useOrganization } from 'global'
import MenuItem from './menu/menuItem'
import { openModal, UserDetails } from 'components'
import './styles.scss'

import Menu from './menu'

export default function Sidebar(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { isOpen } = props
  const { me } = useMe()
  const { organization: org } = useOrganization()
  const session = useSession()
  const api = useAPI()

  const logout = () => {
    api.logout().catch(()=>{})
    session.setAuthentication(null)
    api.orgID = null // Reset the orgID in the API.
  }

  const openProfile = () => openModal(<UserDetails user={me} />)

  return (
    <div id="sidebar-comp" className={isOpen ? null : 'closed'}>
      <div id="layout-overlay" onClick={props.onClose} />
      <div id="sidebar-drawer">
        <div id="sidebar-panel">
          <Menu closeSidebar={props.onClose} />
          {org && <MenuItem icon="person" title={`${me?.firstName} ${me?.lastName}`} onClick={openProfile} />}
          <MenuItem onClick={logout} icon="logout" title={t('profile.logout')} />
        </div>
      </div>
    </div>
  )
}
