import { useTranslation } from 'react-i18next'
import { useAPI } from 'global'
import { Button, confirm } from 'components'
import { KeyType } from 'enums'

// const Inactive = 0
// const Active = 1

export default function KeyCell(props) {
  const { data: k } = props
  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()

  const toggleActive = () => api.saveKey({ ...k, status: k.status ? 0 : 1 }).then(_ => props.onUpdate?.()).catch(()=>{})
  const deleteKey = () => confirm(() => api.deleteKey(k.id).then(_ => props.onUpdate?.()).catch(()=>{}))

  return (
    <div className={`key-cell-comp ${k.status ? 'active' : 'inactive'}`}>
      {k.keyType !== KeyType.ble && <Button id="activate-btn" onClick={toggleActive} flat icon={`toggle_${k.status ? 'on' : 'off'}`} />}
      <div className="info">
        <p className="title">{k.user?.firstName} {k.user?.lastName}</p>
        <p className="subtitle">{k.serialNumber}</p>
        <p className='subtitle'>{k.backupKey ? t('key.backupKey') : t('key.userKey')}</p>
      </div>
      {k.keyType !== KeyType.ble && <Button id="delete-btn" onClick={deleteKey} flat danger icon="delete" />}
    </div>
  )
}
