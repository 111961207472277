import { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { LoadingSpinner } from 'components'
import './styles.scss'

function Checkbox(props, ref) {
  const { onClick: clickCallback, onChange: changeCallback, id, label, ...inputProps } = props
  const [isRunning, setIsRunning] = useState(false)
  const field = useRef()

  useImperativeHandle(ref, ()=>({
    get value() { return field.current.checked },
    set value(v) { field.current.checked = v },
    get checked() { return field.current.checked },
    set checked(v) { field.current.checked = v },
  }))

  const run = (event, callback) => {
    let work = callback(event)
    if (work instanceof Promise) {
      setIsRunning(true)
      work.finally(() => setIsRunning(false))
    }
  }

  const onClick = clickCallback ? event => {
    event.stopPropagation()
    if (!isRunning) run(event, clickCallback)
  } : undefined

  const onChange = changeCallback ? event => {
    if (!isRunning) run(event, changeCallback)
  } : undefined

  let klass = 'checkbox-comp'
  if (props.className) klass += ` ${props.className}`

  const toggle = e => {
    e.stopPropagation()
    // if they click on the outer div, re-trigger the click on the checkbox instead
    if (e.target.localName == 'div') field.current?.click()
  }

  return (
    <div className={klass} onClick={toggle}>
      {isRunning ?
        <LoadingSpinner small />
        :
        <input
          id={id || label}
          onClick={onClick}
          onChange={onChange}
          type='checkbox'
          { ...inputProps }
          ref={field}
        />
      }
      <label htmlFor={id || label}>{label}</label>
    </div>
  )
}

export default forwardRef(Checkbox)
