import { Button } from 'components'
import './styles.scss'

function DefaultCell(props) {
  const { data } = props
  if (typeof data == 'string') return data
  return data?.name // otherwise, try returning the name of the data
}

export default function ListViewScreen(props) {
  const {
    meta,
    data,
    currentPage,
    pageCount,
    onSelect,
    onUpdate,
    onPrevPage,
    onNextPage,
    className,
    alternateStyle,
  } = props

  const Cell = props.cell || DefaultCell
  let list = <></>

  try {
    list = data?.map((listItem, idx) => (
      <div key={listItem?.id || idx} onClick={()=>onSelect?.(listItem)} className='cell'>
        <Cell data={listItem} onUpdate={onUpdate} />
      </div>
    )) || list
  } catch {
    console.error(`ListView invalid data. Expected array, but received: ${typeof data}`)
  }

  const alt = alternateStyle ? 'alternate' : ''

  return (
    <div className={`list-view-comp ${className || ''}`}>
      <div className={alt} id='list-container'>
        {list}
      </div>
      {meta ? (
        <div className='pagination-container'>
          <div className='pagination-controls'>
            <Button icon="chevron_left" flat onClick={onPrevPage} />
            <span>{`${currentPage} / ${pageCount}`}</span>
            <Button icon="chevron_right" flat onClick={onNextPage} />
          </div>
        </div>
      ) : <></>
      }
    </div>
  )
}
