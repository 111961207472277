import { useState, useRef } from 'react'
import { useAPI, history } from 'global'
import { openModal, closeModal, RegionEdit } from 'components'
import Screen from './screen'

export default function Regions() {
  const api = useAPI()
  const [data, setData] = useState()
  const filters = useRef()

  const refresh = () => {
    api.getRegions(filters.current)
      .then(response => setData(response))
      .catch(() => {})
  }

  const query = newFilters => {
    filters.current = newFilters
    refresh()
  }

  const onRegionCreated = () => { closeModal(); refresh() }
  const createRegion = () => openModal(<RegionEdit onFinish={onRegionCreated} />)

  const selectRegion = region => history.push(`/regions/${region.id}`)

  return (
    <Screen
      regions={data?.regions}
      meta={data?.meta}
      onQuery={query}
      onSelect={selectRegion}
      onCreate={createRegion}
    />
  )
}
